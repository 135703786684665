import { Cell } from "@maxgraph/core";
import { capitalize } from '../utils/utils';

export class CloudVisual extends Cell {
    constructor(id, parent, position, name, maps, custom_name, lite_node, extra_properties) {
        super();
        this.id = id;
        this.parent = parent;
        this.position = position;

        this.size = [100, 50];
        this.style = {
            shape: 'rectangle',
            fillColor: '#b5f5ff7f',
            strokeColor: '#000000',
        };

        this.name = name;
        this.type = "cloud";
        this.cell = null;

        //Construct the value
        this.value = capitalize(name) + "\n<<network>>";

        //TODO: Check if the maps, custom_name and lite_node are necessary
        this.maps = maps;
        this.custom_name = custom_name;
        this.lite_node = lite_node;

        this.extra_properties = extra_properties;
    }

    setCellRef(cellRef) {
        this.cell = cellRef;
    }

    setMaps(maps) {
        this.maps = maps;
    }
}

export class PortVisual extends Cell {
    constructor(id, parent, position, name, extra_properties) {
        super();
        this.id = id;
        this.parent = parent;
        this.position = position;

        this.size = [20, 20];
        this.style = {
            shape: 'ellipse',
            fillColor: '#ffffd27f',
            strokeColor: '#000000',
            verticalLabelPosition: 'bottom',
        };

        this.name = name;
        this.type = "port";
        this.cell = null;

        this.value = name;
        this.extra_properties = extra_properties;
    }

    setCellRef(cellRef) {
        this.cell = cellRef;
    }
}

export class ComponentVisual extends Cell {
    //TODO: Check if the json_obj._py_class is necessary (I think not!)
    constructor(id, parent, position, name, properties, extra_properties) {
        super();
        this.id = id;
        this.parent = parent;
        this.position = position;
        this.size = [200, 100];
        this.style = {
            shape: 'rectangle',
            fillColor: '#f0fff07f',
            strokeColor: '#000000',
        };

        this.name = name;
        this.type = "component";
        this.cell = null;

        this.properties = properties;
        this.extra_properties = extra_properties;

        //Construct the value
        let compValue = capitalize(name);
        if (properties.length > 0) {
            compValue += "\n\n";
            properties.forEach(prop => {
                compValue += prop.key + ": " + prop.value + "\n";

            });
        }

        this.value = compValue;
    }

    setCellRef(cellRef) {
        this.cell = cellRef;
    }
}

export class ArtifactVisual extends Cell {
    constructor(id, parent, position, name, extra_properties) {
        super();
        this.id = id;
        this.parent = parent;
        this.position = position;
        this.style = {
            shape: 'rectangle',
            fillColor: '#f0f0f07f',
            strokeColor: '#000000',
        };

        this.name = name;
        this.type = "artifact";
        this.cell = null;

        this.value = capitalize(name);

        //Construct the size
        let textLength = name.length;
        let artXSize = textLength > 13 ? 6 * textLength : 7 * textLength;

        this.size = [artXSize, 25];

        this.extra_properties = extra_properties;
    }

    setCellRef(cellRef) {
        this.cell = cellRef;
    }
}

export class NodeVisual extends Cell {
    //TODO: Check what is the best way to save the components, artifacts, packages and in_ports as children
    constructor(id, parent, position, name, components,
        artifacts, packages, in_ports, extra_properties) {
        super();
        this.id = id;
        this.parent = parent;
        this.position = position;
        this.size = [210, 150];
        this.style = {
            shape: 'rectangle',
            fillColor: '#f1f1f17f',
            strokeColor: '#000000',
            verticalAlign: 'top',
            spacingTop: 5
        };

        this.name = name;
        this.type = "node";
        this.cell = null;

        this.extra_properties = extra_properties;

        //Construct the value
        this.value = capitalize(name);

        this.components = components;
        this.artifacts = artifacts;
        this.packages = packages;
        this.in_ports = in_ports;
    }

    setCellRef(cellRef) {
        this.cell = cellRef;
    }

    setComponents(components) {
        this.components = components;
    }

    setArtifacts(artifacts) {
        this.artifacts = artifacts;
    }

    setPackages(packages) {
        this.packages = packages;
    }

    setInPorts(in_ports) {
        this.in_ports = in_ports;
    }
}

export class PackageVisual {
    //TODO: Check how to represent this - it is like a group and the things visible are the children
    constructor(id, parent, position, name, artifacts, components, packages, ports, full_representation, extra_properties) {
        this.id = id;
        this.parent = parent;
        this.position = position;
        this.size = [100, 100];
        this.style = {
            shape: 'rectangle',
            fillColor: '#eff0ef',
            strokeColor: '#000000',
            verticalAlign: 'top',
            align: 'left',
            spacingTop: 5,
            spacingLeft: 5
        };

        this.name = name;
        this.type = "package";
        this.cell = null;

        this.extra_properties = extra_properties;
        this.full_representation = full_representation;

        //Construct the value
        this.value = capitalize(name);

        this.artifacts = artifacts;
        this.components = components;
        this.packages = packages;
        this.ports = ports;
    }

    setCellRef(cellRef) {
        this.cell = cellRef;
    }

    setArtifacts(artifacts) {
        this.artifacts = artifacts;
    }

    setComponents(components) {
        this.components = components;
    }

    setPackages(packages) {
        this.packages = packages;
    }

    setPorts(ports) {
        this.ports = ports;
    }
}

export class ConnectionVisual extends Cell {
    //TODO: Check if the json_obj._py_class is necessary (I think not!)
    constructor(id, parent, name, sourceCell, targetCell, connector_type, extra_properties) {
        super();
        this.id = id;
        this.parent = parent;
        this.source = sourceCell;
        this.target = targetCell;

        this.name = name; //TODO: This name can be more descriptive (src + target + link text?)
        this.type = "connection";
        this.cell = null;

        this.value = name;

        this.connector_type = connector_type;

        //Construct the style
        this.style = {
            edgeStyle: 'orthogonalEdgeStyle', //make this dependent on the connector_type - SIMPLE DASHED_ARROW ARROW
            rounded: true,
            strokeColor: '#000000',
            fontColor: '#000000',
        };

        this.extra_properties = extra_properties;
    }

    setCellRef(cellRef) {
        this.cell = cellRef;
    }
}
