import { ReactTyped } from 'react-typed';
import ButtonLink from '../components/ButtonLink';
import RepoDetailsForm from '../components/RepoDetailsForm';
import docker_diagram_example from '../assets/docker_to_diagram.png';

function Home() {
    return (
      <div className="container text-center mx-auto">
        <div className='row'>
          <div className="col-lg-5">
            <h1 className="display-5 mb-3 text-start fw-medium" id="home_banner">
              Turn your <span className='text-primary' >docker-compose</span> file into a diagram easy to {" "}
              <span className="text-primary">
                <ReactTyped
                  strings={['', 'understand.', 'visualize.', 'edit.']}
                  typeSpeed={40}
                  loop
                  backSpeed={60}
                  showCursor={true} />
              </span>
            </h1>
            <div className='d-flex justify-content-between mt-xl-4 mt-sm-1' id="home_banner_btns">
              <ButtonLink classes='btn btn-primary btn-resized' link='#repo-form' btnText='Get started' />
              <ButtonLink classes='btn btn-outline-primary btn-resized' link='#learn-more' btnText='Learn more' icon='bi bi-chevron-right' />
            </div>
          </div>
          <div className="col-lg-1">
          </div>
          <div className="col-lg-6 mt-sm-3">
            <img src={docker_diagram_example} className="img-fluid" alt="Example Diagram"></img>
          </div>
        </div>
        <div className='row bg-light rounded-4 text-secondary mt-5' id="learn-more">
          <div className='row text-center mt-5'>
            <h3>Add an infrastructure diagram to your repo</h3>
            <p>through simple and effortless steps</p>
          </div>
          <div className='row text-center mb-2'>
            <ul className='list-inline'>
              <li className='list-inline-item text-secondary p-4' style={{ fontSize: '0.85rem' }}><i className="bi bi-check text-primary pe-2"/>uml compliant</li>
              <li className='list-inline-item text-secondary p-4' style={{ fontSize: '0.85rem' }}><i className="bi bi-check text-primary pe-2"/>editable</li>
              <li className='list-inline-item text-secondary p-4' style={{ fontSize: '0.85rem' }}><i className="bi bi-check text-primary pe-2"/>constant updates</li>
            </ul>
          </div>
          <div className='row'>
            <div className='col-lg-6 px-sm-5 mb-sm-3'>
              <h4 className="text-primary text-highlight-warning">Add a diagram to your repository</h4>
              <p>You will receive a pull request on your repository which you can verify before merge. Add it to your readme or just leave it in your repository to consult later.</p>
              <ul className="list-unstyled list-checked list-checked-primary text-start">
                <li className="list-checked-item p-2 ps-5"><i className="bi bi-1-circle-fill text-primary pe-2" />Add your repository</li>
                <li className="list-checked-item p-2 ps-5"><i className="bi bi-2-circle-fill text-primary pe-2" />Choose a branch</li>
                <li className="list-checked-item p-2 ps-5"><i className="bi bi-3-circle-fill text-primary pe-2" />Optionally add it to readme</li>
                <li className="list-checked-item p-2 ps-5"><i className="bi bi-4-circle-fill text-primary pe-2" />Press analyze</li>
                <li className="list-checked-item p-2 ps-5"><i className="bi bi-5-circle-fill text-primary pe-2" />Check the PR in your repository</li>
              </ul>
            </div>
            <div className='col-lg-6 px-sm-5 mb-sm-3'>
              <h4 className="text-primary text-highlight-warning">Preview and edit</h4>
              <p>You can verify the diagram and change it before or after adding it to your repository. All your edits will be saved, and you can add changes whenever you need.</p>
              <ul className="list-unstyled list-checked list-checked-primary text-start">
                <li className="list-checked-item p-2 ps-5"><i className="bi bi-1-circle-fill text-primary pe-2" />Add your repository</li>
                <li className="list-checked-item p-2 ps-5"><i className="bi bi-2-circle-fill text-primary pe-2" />Choose a branch</li>
                <li className="list-checked-item p-2 ps-5"><i className="bi bi-3-circle-fill text-primary pe-2" />Optionally add it to readme</li>
                <li className="list-checked-item p-2 ps-5"><i className="bi bi-4-circle-fill text-primary pe-2" />Press Preview & Edit</li>
                <li className="list-checked-item p-2 ps-5"><i className="bi bi-5-circle-fill text-primary pe-2" />Use the selected editor to add changes</li>
                <li className="list-checked-item p-2 ps-5"><i className="bi bi-6-circle-fill text-primary pe-2" />Submit your diagram</li>
                <li className="list-checked-item p-2 ps-5"><i className="bi bi-7-circle-fill text-primary pe-2" />Check the PR in your repository</li>
              </ul>
            </div>
          </div>
          <div className='row text-center mt-5'>
            <ButtonLink classes='btn btn-primary btn-resized mx-auto' link='#repo-form' btnText='Get started' />
          </div>
          <div className='row mb-5'>
            <hr className='my-5 ms-sm-3' />
            <p className="text-muted small">Need more info? <a href="#contacts">Contact us.</a></p>
          </div>
        </div>
        <RepoDetailsForm
          formTitle="Enter your repository name"
          inEditorPage={false}
          leftBtnText="Analyze"
        />
      </div>
    );
}
export default Home;