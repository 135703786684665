import { revert_all, revert_to_original, toggle_revert_all,
    getCloneElementsMapAt, changesOperation, process_graph,
    preview_graph} from "./plantUML";

export function get_wrapper() {
    let wrapper_item = document.createElement("div");
    wrapper_item.className = "wrapper";
    return wrapper_item;
}

export function get_revert_all_icon(show) {
    let div_group = document.createElement("div");
    div_group.id = 'revert-all';
    div_group.style.display = show ? 'inline-block' : 'none';
    div_group.className = "btn-group";
    let revert_button = document.createElement("span");
    revert_button.type = "button";
    revert_button.className = "pe-1";
    revert_button.setAttribute("data-bs-toggle", "dropdown");
    revert_button.setAttribute("aria-expanded", "false");
    let revert_icon = document.createElement("i");
    revert_icon.classList.add('bi', 'bi-arrow-counterclockwise', 'revert');
    revert_button.appendChild(revert_icon);

    let unordered_list = document.createElement("ul");
    unordered_list.className = "dropdown-menu";

    let revert_list_item = document.createElement("li");
    let revert_item = document.createElement("button");
    revert_item.className = "dropdown-item";
    let revert_text = document.createElement("span");
    revert_text.innerText = 'revert all ';
    let revert_exclamation = document.createElement("i");
    revert_exclamation.classList.add('bi', 'bi-exclamation-triangle-fill', 'revert');
    revert_item.appendChild(revert_text);
    revert_item.appendChild(revert_exclamation);

    revert_item.addEventListener('click', (el) => {
        el.preventDefault();
        revert_all();
    });
    revert_list_item.appendChild(revert_item);
    unordered_list.appendChild(revert_list_item);

    div_group.appendChild(revert_button);
    div_group.appendChild(unordered_list);

    return div_group;
}

export function get_revert_icon(uml_object, property, html_element) {
    let div_group = document.createElement("div");
    div_group.style.display = 'none';
    div_group.className = "btn-group";
    if (getCloneElementsMapAt(uml_object.id) === null ||
        getCloneElementsMapAt(uml_object.id) === undefined) {
        return div_group;
    }
    const original_text = getCloneElementsMapAt(uml_object.id)[property];
    let revert_button = document.createElement("span");
    revert_button.type = "button";
    revert_button.className = "pe-1";
    revert_button.setAttribute("data-bs-toggle", "dropdown");
    revert_button.setAttribute("aria-expanded", "false");
    let revert_icon = document.createElement("i");
    revert_icon.classList.add('bi', 'bi-arrow-counterclockwise', 'revert');
    revert_button.appendChild(revert_icon);

    let unordered_list = document.createElement("ul");
    unordered_list.className = "dropdown-menu";

    let revert_list_item = document.createElement("li");
    let revert_item = document.createElement("button");
    revert_item.className = "dropdown-item";
    let revert_text = document.createElement("span");
    revert_text.innerText = 'revert to: ';
    let ori_text = document.createElement("em");
    ori_text.innerText = `${original_text}`;
    ori_text.style.fontWeight = 'bold';
    revert_item.appendChild(revert_text);
    revert_item.appendChild(ori_text);

    revert_item.addEventListener('click', (el) => {
        el.preventDefault();
        revert_to_original(uml_object, original_text, property, html_element, div_group);
    });

    revert_list_item.appendChild(revert_item);
    unordered_list.appendChild(revert_list_item);

    div_group.appendChild(revert_button);
    div_group.appendChild(unordered_list);

    return div_group;
}


export function get_secondary_menu(wrapper_element, uml_object, list_action_objects = []) {
    let div_group = document.createElement("div");
    div_group.className = "btn-group";
    let edit_button = document.createElement("span");
    edit_button.type = "button";
    edit_button.className = "pe-4 mb-1 mt-1" + (uml_object.parent_id === null ? " ms-3" : ""); //If graph object add margin-start
    edit_button.setAttribute("data-bs-toggle", "dropdown");
    edit_button.setAttribute("aria-expanded", "false");
    let edit_symbol = document.createElement("i");
    edit_symbol.className = "btn btn-light bi bi-grid-3x2-gap-fill";
    edit_button.appendChild(edit_symbol);

    let unordered_list = document.createElement("ul");
    unordered_list.className = "dropdown-menu";

    let delete_list_item = document.createElement("li");
    let delete_item = document.createElement("button");
    delete_item.className = "dropdown-item";
    delete_item.innerText = "delete";

    delete_item.addEventListener('click', (el) => {
        el.preventDefault();
        edit_button.classList.remove("show");
        unordered_list.classList.remove("show");

        if (getCloneElementsMapAt(uml_object.id) !== undefined) {
            //If the object is in the clone elements map, it means that it is an original object
            if (changesOperation('deletions', 'has', uml_object.id)) {
                wrapper_element.classList.remove('delete-item');
                changesOperation('deletions', 'delete', uml_object.id);
                delete_item.innerText = "delete";
            } else {
                wrapper_element.classList.add('delete-item');
                changesOperation('deletions', 'add', uml_object.id)
                delete_item.innerText = "add";
            }
        }

        if (changesOperation('additions', 'has', uml_object.id)) {
            //If the object is in the additions set, it means that it is a new object
            let deleted_objs = uml_object.remove_child_and_parent_links();
            // just to give a little help to the garbage collector
            for (let i = 0; i < deleted_objs.length; i++) {
                deleted_objs[i] = null;
            }

            process_graph();
        } else {
            preview_graph();
        }
        toggle_revert_all();
    });

    delete_list_item.appendChild(delete_item);
    unordered_list.appendChild(delete_list_item);

    for (let action of list_action_objects) {
        let action_list_item = document.createElement("li");
        let action_item = document.createElement("button");
        action_item.className = "dropdown-item";
        action_item.innerText = action.html_action_name();
        action_item.addEventListener('click', (el) => {
            el.preventDefault();
            edit_button.classList.remove("show");
            unordered_list.classList.remove("show");
            action.html_action_execute(uml_object.id);
        });

        action_list_item.appendChild(action_item);
        unordered_list.appendChild(action_list_item);
    }

    div_group.appendChild(edit_button);
    div_group.appendChild(unordered_list);

    return div_group;
}

export function add_element_name(name, father) {
    let name_item = document.createElement("samp");
    name_item.innerText = `${name} `;
    name_item.classList.add('element-name');
    name_item.classList.add('first');
    father.appendChild(name_item);
}

export function add_id_element(id, father, alias = true) {
    let as_connector = document.createElement("samp");
    as_connector.innerText = ` as `;
    as_connector.className = 'static-connectors';
    let id_element = document.createElement("samp");
    id_element.innerText = `${id}`;
    id_element.className = 'elements-ids';

    id_element.addEventListener('click', () => {
        navigator.clipboard.writeText(id_element.innerText);
    });

    if (alias) {
        father.appendChild(as_connector);
    }
    father.appendChild(id_element);
}

export function add_samp_inline_text(text, father) {
    let samp_element = document.createElement("samp");
    samp_element.innerText = text;
    father.appendChild(samp_element)
}

export function add_opening_bracket(braket, father) {
    let open_bracket = document.createElement("samp");
    open_bracket.innerText = ` ${braket}`;
    open_bracket.classList.add('element-brackets');
    father.appendChild(open_bracket);
}

export function add_closing_bracket(bracket, father) {
    let close_item = document.createElement("li");
    close_item.id = "uml-item";
    let close_bracket = document.createElement("samp");
    close_bracket.innerText = bracket;
    close_bracket.classList.add('element-brackets');
    close_bracket.classList.add('closing-bracket');
    close_item.appendChild(close_bracket);
    father.appendChild(close_item);
}

export function add_get_inside(father, callback) {
  let inside_icon = document.createElement("i");
  inside_icon.className = "bi bi-box-arrow-in-down move-inside-btn";
  inside_icon.addEventListener('click', callback);

  let span = document.createElement("span");
  span.appendChild(inside_icon);
  father.appendChild(span);
}

export function add_get_outside(father, callback) {
  let inside_icon = document.createElement("i");
  inside_icon.className = "bi bi-box-arrow-up move-inside-btn";
  inside_icon.addEventListener('click', callback);

  let span = document.createElement("span");
  span.appendChild(inside_icon);
  father.appendChild(span);
}
