import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import PlantUMLEditor from './pages/PlantUMLEditor';
import VisualEditor from './pages/VisualEditor';
import BadRepo from './pages/BadRepo';
import NotFound from './pages/NotFound';
import NavBar from './components/Navbar';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <NavBar />
        <Router>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/view/plantuml" element={<PlantUMLEditor />} />
            <Route path="/view/visual" element={<VisualEditor />} />
            <Route path="/bad_repo" element={<BadRepo />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      <Footer />
    </>
  );
}

export default App;
