import { useEffect, useCallback, useState } from 'react';
import { Graph, InternalEvent } from '@maxgraph/core';
import RepoDetailsForm from '../components/RepoDetailsForm';
import BackendApi from '../BackendApi';
import {
  ArtifactVisual, CloudVisual, ComponentVisual,
  ConnectionVisual, NodeVisual, PackageVisual,
  PortVisual
} from '../visualEditor/visualEditorElements';
import EditMenuVisual from '../components/EditMenuVisual';

class GraphVisual {
  constructor() {
    this.maxGraph = null;
    this.elements = [];
    this.links = [];

    this.editorDiv = null;
    this.defaultParent = null;

    this.findElement = this.findElement.bind(this);
    this.findElementChildren = this.findElementChildren.bind(this);
    this.findElementOutLinks = this.findElementOutLinks.bind(this);
    this.findElementInLinks = this.findElementInLinks.bind(this);
    this.findLink = this.findLink.bind(this);

    //TODO: Should I keep a copy of the original elements and links?

    this.initMaxGraph();
  }

  initMaxGraph() {
    this.editorDiv = document.getElementById('visual-editor');
    this.editorDiv.childNodes.forEach((child) => {
      this.editorDiv.removeChild(child);
    });

    // Disables the built-in context menu
    InternalEvent.disableContextMenu(this.editorDiv);
    this.maxGraph = new Graph(this.editorDiv);

    this.maxGraph.setPanning(true); // Use mouse right button for panning
    this.maxGraph.setConnectable(true); // Enables new connections created by dragging from a vertex when it is highlighted green
    this.defaultParent = this.maxGraph.getDefaultParent(); // Gets the default parent for inserting new cells. This is normally the first child of the root (ie. layer 0).
  }

  findElement(id) {
    return this.elements.find(element => element.id === id);
  }

  findElementChildren(id) {
    return this.elements.filter(element => element.parent.id === id);
  }

  findElementOutLinks(id) {
    return this.links.filter(link => link.source.id === id);
  }

  findElementInLinks(id) {
    return this.links.filter(link => link.target.id === id);
  }

  findLink(id) {
    return this.links.find(link => link.id === id);
  }

  buildClouds(cloudsData, parentCell = this.defaultParent) {
    let createdClouds = [];

    this.maxGraph.batchUpdate(() => {
      let cloudCount = 0;
      cloudsData.forEach(cloud => {
        let parent = parentCell === this.defaultParent ? this.defaultParent : this.maxGraph.model.getCell(parentCell.id);

        let newCloud = new CloudVisual(
          cloud.id, parent, [10 + 100 * cloudCount, 10],
          cloud.name, cloud.properties, cloud.custom_name,
          cloud.lite_node, cloud.extra_properties);

        let cloudCell = this.maxGraph.insertVertex(newCloud);
        newCloud.setCellRef(cloudCell);

        cloudCount++;
        createdClouds.push(newCloud);

        //NOTE: The cloud properties are not being represented in the PlantUML diagram (why?)
        // if(cloud.properties.length > 0) {
        //   let cloudPropertyMaps = this.buildMaps(cloud.properties, newCloud);
        //   createdClouds.push(...cloudPropertyMaps);
        // }
      });
    });

    return createdClouds;
  }

  // buildMaps(mapsData, parentCell = this.defaultParent) {
  //   let createdMaps = [];

  //   this.maxGraph.batchUpdate(() => {
  //     let mapCount = 0;

  //     mapsData.forEach(map => {
  //       let mapText = map.name;
  //       if (map.properties.length > 0) {
  //         mapText += '\n\n';
  //         map.properties.forEach(prop => {
  //           mapText += prop.key + ': ' + prop.value + '\n';
  //         });
  //       }

  //       let newMap = this.maxGraph.insertVertex({
  //         id: map.id,
  //         parent: parentCell,
  //         position: [10 + 100 * mapCount, 10],
  //         size: [100, 100],
  //         value: mapText,
  //         style: {
  //           shape: 'rectangle',
  //           fillColor: '#f0f0f07f',
  //           strokeColor: '#000000',
  //         },
  //         extra_properties: map.extra_properties,
  //       });

  //       mapCount++;
  //       createdMaps.push(newMap);

  //       //Constitution of the map object
  //       //json_obj.id, json_obj.parent_id, json_obj.extra_properties, json_obj.name, props;
  //     });

  //     console.log("Created maps: ", createdMaps)
  //   });

  //   return createdMaps;
  // }

  buildPorts(portsData, parentCell = this.defaultParent) {
    let createdPorts = [];

    this.maxGraph.batchUpdate(() => {
      let portCount = 0;
      portsData.forEach(port => {
        let parent = parentCell === this.defaultParent ? this.defaultParent : this.maxGraph.model.getCell(parentCell.id);

        let newPort = new PortVisual(
          port.id, parent, [10 + 30 * portCount, 25],
          port.name, port.extra_properties);

        let portCell = this.maxGraph.insertVertex(newPort);
        newPort.setCellRef(portCell);

        portCount++;
        createdPorts.push(newPort);
      });
    });

    return createdPorts;
  }

  buildComponents(componentsData, parentCell = this.defaultParent) {
    let createdComponents = [];

    this.maxGraph.batchUpdate(() => {
      let componentCount = 0;
      componentsData.forEach(component => {
        let parent = parentCell === this.defaultParent ? this.defaultParent : this.maxGraph.model.getCell(parentCell.id);

        let newComponent = new ComponentVisual(
          component.id, parent, [5 + 200 * componentCount, 25],
          component.name, component.properties, component.extra_properties);

        let componentCell = this.maxGraph.insertVertex(newComponent);
        newComponent.setCellRef(componentCell);

        componentCount++;
        createdComponents.push(newComponent);
      });
    });

    return createdComponents;
  }

  buildArtifacts(artifactsData, parentCell = this.defaultParent) {
    let createdArtifacts = [];

    this.maxGraph.batchUpdate(() => {
      let artifactCount = 0;
      artifactsData.forEach(artifact => {
        let parent = parentCell === this.defaultParent ? this.defaultParent : this.maxGraph.model.getCell(parentCell.id);

        let newArtifact = new ArtifactVisual(
          artifact.id, parent, [10 + 100 * artifactCount, 35],
          artifact.name, artifact.extra_properties);

        let artifactCell = this.maxGraph.insertVertex(newArtifact);
        newArtifact.setCellRef(artifactCell);

        artifactCount++;
        createdArtifacts.push(newArtifact);
      });
    });

    return createdArtifacts;
  }

  buildNodes(nodesData, parentCell = this.defaultParent) {
    let createdNodes = [];

    this.maxGraph.batchUpdate(() => {
      let nodeCount = 0;
      nodesData.forEach(node => {
        let parent = parentCell === this.defaultParent ? this.defaultParent : this.maxGraph.model.getCell(parentCell.id);

        let newNode = new NodeVisual(
          node.id, parent, [10 + 250 * nodeCount, 340],
          node.name, node.components, node.artifacts,
          node.packages, node.in_ports, node.extra_properties);

        let nodeCell = this.maxGraph.insertVertex(newNode);
        newNode.setCellRef(nodeCell);

        nodeCount++;
        createdNodes.push(newNode);

        //TODO: Check if there is a better way to do this
        if (node.components.length > 0) {
          let components = this.buildComponents(node.components, newNode);
          createdNodes.push(...components);

          newNode.setComponents(components);
        }

        if (node.artifacts.length > 0) {
          let artifacts = this.buildArtifacts(node.artifacts, newNode);
          createdNodes.push(...artifacts);

          newNode.setArtifacts(artifacts);
        }

        if (node.packages.length > 0) {
          let packages = this.buildPackages(node.packages, newNode);
          createdNodes.push(...packages);

          newNode.setPackages(packages);
        }
      });
    });

    return createdNodes;
  }

  buildPackages(packagesData, parentCell = this.defaultParent) {
    let createdPackages = [];

    this.maxGraph.batchUpdate(() => {
      let packageCount = 0;

      packagesData.forEach(packg => {
        let parent = parentCell === this.defaultParent ? this.defaultParent : this.maxGraph.model.getCell(parentCell.id);

        let newPackage = new PackageVisual(
          packg.id, parent, [5 + 200 * packageCount, 25], packg.name,
          packg.artifacts, packg.components, packg.packages, packg.ports, packg.full_representation,
          packg.extra_properties);

        if (packg.full_representation) {
          let packageCell = this.maxGraph.insertVertex(newPackage);
          newPackage.setCellRef(packageCell);

          packageCount++;
          createdPackages.push(newPackage); //The get element with a package id is not working
        }

        let parentForChildren = packg.full_representation ? newPackage : parent;
        //TODO: Check if there is a better way to do this -> children of packages are being added to the parent cell of the package (a node or the graph itself)
        if (packg.artifacts.length > 0) {
          let artifacts = this.buildArtifacts(packg.artifacts, parentForChildren);
          createdPackages.push(...artifacts);

          if (packg.full_representation) {
            newPackage.setArtifacts(artifacts);
          }
        }

        if (packg.components.length > 0) {
          let components = this.buildComponents(packg.components, parentForChildren);
          createdPackages.push(...components);

          if (packg.full_representation) {
            newPackage.setComponents(components);
          }
        }

        if (packg.packages.length > 0) {
          let packages = this.buildPackages(packg.packages, parentForChildren);
          createdPackages.push(...packages);

          if (packg.full_representation) {
            newPackage.setPackages(packages);
          }
        }

        if (packg.ports.length > 0) {
          let ports = this.buildPorts(packg.ports, parentForChildren);
          createdPackages.push(...ports);

          if (packg.full_representation) {
            newPackage.setPorts(ports);
          }
        }
      });
    });

    return createdPackages;
  }

  buildConnections(connectionsData, parentCell = this.defaultParent) {
    let createdConnections = [];

    this.maxGraph.batchUpdate(() => {
      connectionsData.forEach(connection => {
        let parent = parentCell === this.defaultParent ? this.defaultParent : this.maxGraph.model.getCell(parentCell.id);
        let source = this.maxGraph.model.getCell(connection.from_id);
        let target = this.maxGraph.model.getCell(connection.to_id);

        let newConnection = new ConnectionVisual(
          connection.id, parent, connection.name,
          source, target, connection.connector_type,
          connection.extra_properties);

        let connectionCell = this.maxGraph.insertEdge(newConnection);
        newConnection.setCellRef(connectionCell);

        createdConnections.push(newConnection);
      });
    });

    return createdConnections;
  }

  buildFromJson(jsonData) {
    let clouds = this.buildClouds(jsonData.clouds);
    let ports = this.buildPorts(jsonData.ports);
    let components = this.buildComponents(jsonData.components);
    let artifacts = this.buildArtifacts(jsonData.artifacts);
    let nodes = this.buildNodes(jsonData.nodes);
    let packages = this.buildPackages(jsonData.packages);
    let connections = this.buildConnections(jsonData.connections);

    this.elements = [...clouds, ...ports, ...components, ...artifacts, ...nodes, ...packages];
    this.links = connections;


    let graphId = jsonData.id;
    let graphParentId = jsonData.parent_id;
    let graphExtraProperties = jsonData.extra_properties;

    console.log("Graph elements created: ", this.elements);
    console.log("Graph links created: ", this.links);
    console.log(`Graph extra props:\n Id: ${graphId}\n GraftParentId: ${graphParentId}\n ExtraProps: ${graphExtraProperties}`);
  }
}

function VisualEditor() {

  let searchParams = new URL(window.location.href).searchParams;
  let repoName = searchParams.get('repo');
  let branch = searchParams.get('branch');
  let readme = searchParams.get('readme');

  const [pageDisplay, setPageDisplay] = useState('none');
  const [graph, setGraph] = useState(null);
  const [changes, setChanges] = useState([]);  //This is going to be the changelog for the graph (useful for undo/redo feature)

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'i') { //multiple keys can be checked
        console.log('Saving the graph\nCHANGELOG:');
        for (let i = 0; i < changes.length; i++) {
          console.log(changes[i]);
        }

        return;
      }

      // if (e.key === 'Enter') {
      //   console.log("Adding vert to graph");

      //   let vertex
      //   graph.maxGraph.batchUpdate(() => {
      //     vertex = graph.maxGraph.insertVertex({
      //       parent: graph.maxGraph.getDefaultParent(),
      //       position: [200, 100],
      //       size: [100, 100],
      //       value: 'newRect',
      //     });
      //   });


      //   setGraph(prevGraph => ({
      //     ...prevGraph,
      //     'elements': [...prevGraph.elements, vertex.value], //maybe here add the vertex obj
      //   }));

      //   setChanges([...changes, 'ADD vert at ' + new Date().toString()]);
      //   return;
      // }

      // if (e.key === 'Backspace') {
      //   console.log("Adding edge to graph");

      //   setGraph(prevGraph => ({
      //     ...prevGraph,
      //     'links': [...graph.links, 'edge']
      //   }));

      //   setChanges([...changes, 'ADD edge at ' + new Date().toString()]);
      //   return;
      // }

      if (e.key === 'Delete') {
        if (graph.elements.length > 0) {
          console.log("Deleting cells from graph");

          let cellsToDel = graph.maxGraph.getSelectionCells();

          let newChanges = [];
          let updatedElements = graph.elements;
          let updatedLinks = graph.links;
          cellsToDel.forEach(cell => {
            console.log("cell: ", cell);
            let text = "";

            if (cell.vertex) {
              text = "vertex " + cell.id + " " + cell.value; //TODO: Add the element type here later
            } else {
              text = "edge " + cell.id;

              if (cell.source !== null) {
                text += " Source: " + (cell.source.name !== undefined ? cell.source.name : "") + "(" + cell.source.id + ")";
              }

              if (cell.target !== null) {
                text += " Target: " + (cell.target.name !== undefined ? cell.target.name : "") + "(" + cell.target.id + ")";
              }
            }

            newChanges.push(`DEL ${text} at ${new Date().toString()}`);

            if (cell.vertex) {
              //TODO: Remove the element by id, its children if any and the links connected to it 
              //TODO: This has to be recursive for the children -> maybe use something different than filter? + add them to the newChanges?
              updatedElements = updatedElements.filter((item) => item.id !== cell.id && item.parent.id !== cell.id);
              console.log(updatedElements);

              updatedLinks = updatedLinks.filter((item) => item.source !== null && item.source !== undefined && item.source.id !== cell.id && item.target !== null && item.target !== undefined && item.target.id !== cell.id);
              console.log(updatedLinks);
            } else {
              updatedLinks = updatedLinks.filter((item) => item.id !== cell.id); //TODO: Check if sourceId and targetId are needed here
              console.log(updatedLinks);
            }
          });

          setChanges([...changes, ...newChanges]);

          graph.maxGraph.removeCells(cellsToDel);

          setGraph(prevGraph => ({
            ...prevGraph,
            'elements': updatedElements,
            'links': updatedLinks
          }));

          return;
        }
      }
    },
    [graph, changes]
  );

  useEffect(() => {
    if (pageDisplay === 'none') {
      BackendApi.get('graph/visual', { params: { repo: repoName, branch: branch } }).then((res) => {
        if (res.status === 200) {
          console.log("Graph data: ", res.data);
          const {json, pickle} = res.data;
          let newGraph = new GraphVisual();
          newGraph.buildFromJson(json);

          setGraph(newGraph);
          setPageDisplay('block');
        }
      }).catch((err) => {
        if (err.response !== undefined) {
          if (err.response.status !== 303) {
            //If it is not the expected error, log it
            console.error("Error getting graph (" + err.response.status + "): ", err.response.data);
          }
        } else {
          console.error("Error building graph: ", err);
        }

        window.location.href = '/bad_repo';
      });
    }

    //Define all the event listeners here
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    }
  }, [pageDisplay, repoName, branch, graph, handleKeyDown]);

  return (
    <>
      <div className='container text-center mx-auto' id="loader" style={{ display: pageDisplay === "none" ? "block" : "none", paddingTop: "300px", paddingBottom: "300px" }} >
        <div className="spinner-grow" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
      <div className="container text-start mx-auto" style={{ display: pageDisplay }}>
        <div className='row mb-4'>
          <h3 className="text-primary mb-3">
            Update the diagram with our visual editor!
          </h3>
          <p className="text-secondary">
            This text should have some indications on how to use the visual editor.
          </p>
        </div>
        <div className='row shadow-lg p-3 bg-body rounded' id="visual-editor-window">
          <div className="col-2 shadow-sm" id="left-menu">
            <p>left side menu</p>
            <p>THIS IS THE VISUAL EDITOR</p>
          </div>
          <div className="col-7 shadow-sm overflow-hidden" id="visual-editor">
          </div>
          <EditMenuVisual graph={graph} setGraph={setGraph} changes={changes} setChanges={setChanges} />
        </div>
        <RepoDetailsForm className="row"
          formTitle="Add the updated diagram to your repository"
          inEditorPage={true}
          leftBtnText="Submit changes"
          activeEditor="visual"
          repoName={repoName}
          branch={branch}
          readme={readme}
        />
      </div>
    </>
  );
}
export default VisualEditor;