import BackendApi from "../BackendApi";
import {
    get_wrapper, get_secondary_menu, get_revert_all_icon, get_revert_icon,
    add_closing_bracket, add_samp_inline_text, add_element_name, add_id_element,
    add_opening_bracket,
    add_get_inside,
    add_get_outside
} from "./htmlGenerator";
import { capitalize, addAlertMessage, cleanAllAlertMessages, delay} from "../utils/utils";


let updateGraphsStack = () => {};
let setImageSrc = () => {};
let initialGraphPickle = null;
let isSublevel = false;
export function setUpdateGraphsStack(func) {
    updateGraphsStack = func;
}
export function setInitialGraph(pickle) {
    initialGraphPickle = pickle;
}
export function setImageSrcSetter(setter) {
    setImageSrc = setter;
}



class GraphPlantUML {
    constructor(id, parent_id, extra_properties, py_class, clouds, ports, components, artifacts, nodes, packages, connections) {
        this.id = id;
        this.parent_id = parent_id;
        this.extra_properties = extra_properties;
        this.clouds = clouds;
        this.ports = ports;
        this.components = components;
        this.artifacts = artifacts;
        this.nodes = nodes;
        this.packages = packages;
        this.connections = connections;
        this.py_class = py_class;
    }

    static build_clouds(clouds_array) {
        let clouds_objs = [];
        for (let could_obj of clouds_array) {
            clouds_objs.push(CloudUML.build_from_json_object(could_obj));
        }
        return clouds_objs;
    }

    static build_ports(ports_array) {
        let ports_objs = [];
        for (let port_obj of ports_array) {
            ports_objs.push(PortUML.build_from_json_object(port_obj));
        }

        return ports_objs;
    }

    static build_components(components_array) {
        let components_objs = [];
        for (let component_obj of components_array) {
            components_objs.push(ComponentUML.build_from_json_object(component_obj));
        }

        return components_objs;
    }

    static build_artifacts(artifacts_array) {
        let artifacts_objs = [];
        for (let artifact_obj of artifacts_array) {
            artifacts_objs.push(ArtifactUML.build_from_json_object(artifact_obj));
        }

        return artifacts_objs;
    }

    static build_nodes(nodes_array) {
        let node_objs = [];
        for (let node_obj of nodes_array) {
            node_objs.push(NodeUML.build_from_json_object(node_obj));
        }

        return node_objs;
    }

    static build_packages(packages_array) {
        let packages_objs = [];
        for (let package_obj of packages_array) {
            packages_objs.push(PackageUML.build_from_json_object(package_obj));
        }

        return packages_objs;
    }

    static build_connections(connections_array) {
        let connections_objs = [];
        for (let connector_obj of connections_array) {
            connections_objs.push(ConnectorUML.build_from_json_object(connector_obj));
        }

        return connections_objs;
    }

    static build_from_json_object(json_obj) {
        let clouds = json_obj.clouds ? GraphPlantUML.build_clouds(json_obj.clouds) : [];
        let ports = json_obj.ports ? GraphPlantUML.build_ports(json_obj.ports) : [];
        let components = json_obj.components ? GraphPlantUML.build_components(json_obj.components) : [];
        let artifacts = json_obj.artifacts ? GraphPlantUML.build_artifacts(json_obj.artifacts) : [];
        let nodes = json_obj.nodes ? GraphPlantUML.build_nodes(json_obj.nodes) : [];
        let packages = json_obj.packages ? GraphPlantUML.build_packages(json_obj.packages) : [];
        let connections = json_obj.connections ? GraphPlantUML.build_connections(json_obj.connections) : [];

        let graph_uml = new GraphPlantUML(
            json_obj.id,
            json_obj.parent_id,
            json_obj.extra_properties,
            json_obj.py_class,
            clouds,
            ports,
            components,
            artifacts,
            nodes,
            packages,
            connections
        );

        elements_map[graph_uml.id] = graph_uml;
        clone_elements_map[graph_uml.id] = GraphPlantUML.assign(graph_uml);
        return graph_uml;
    }

    _menu_options() {
      if (this.py_class === 'ComponentDiagram') {
        return [ComponentUML, ArtifactUML, PackageUML, ConnectorUML];
      } else if (this.py_class === 'DeploymentDiagram') {
        return [CloudUML, PortUML, ComponentUML, ArtifactUML, NodeUML, PackageUML, ConnectorUML];
      } else {
        return [CloudUML, PortUML, ComponentUML, ArtifactUML, NodeUML, PackageUML, ConnectorUML];
      }
    }

    static assign(obj) {
        let clouds = [];
        let ports = [];
        let components = [];
        let artifacts = [];
        let nodes = [];
        let packages = [];
        let connections = [];

        for (let cloud of obj.clouds) {
            clouds.push(clone_elements_map[cloud.id]);
        }

        for (let port of obj.ports) {
            ports.push(clone_elements_map[port.id]);
        }

        for (let component of obj.components) {
            components.push(clone_elements_map[component.id]);
        }

        for (let artifact of obj.artifacts) {
            artifacts.push(clone_elements_map[artifact.id]);
        }

        for (let node of obj.nodes) {
            nodes.push(clone_elements_map[node.id]);
        }

        for (let pckg of obj.packages) {
            packages.push(clone_elements_map[pckg.id]);
        }

        for (let connection of obj.connections) {
            connections.push(clone_elements_map[connection.id]);
        }

        return new GraphPlantUML(
            obj.id,
            obj.parent_id,
            { ...obj.extra_properties },
            obj.py_class,
            clouds,
            ports,
            components,
            artifacts,
            nodes,
            packages,
            connections
        );
    }

    html_representation() {
        let wrapper_item = get_wrapper();
        let graph_item = document.createElement("span");
        graph_item.id = "uml-item";
        let edit_item = get_secondary_menu(
            wrapper_item,
            this,
            this._menu_options()
        );
        edit_item.style.marginBottom = '1.5em';
        graph_item.appendChild(edit_item);
        if (isSublevel) {
          add_get_outside(graph_item, () => {
              isSublevel = updateGraphsStack();
          })
        }
        let revert_icon = get_revert_all_icon(changes.modifications.size > 0);
        revert_icon.style.marginBottom = '1.5em';
        graph_item.appendChild(revert_icon);
        wrapper_item.appendChild(graph_item);

        // Process Networks
        for (let cloud of this.clouds) {
            wrapper_item.appendChild(cloud.html_representation()[0]);
        }

        //process ports
        for (let port of this.ports) {
            wrapper_item.appendChild(port.html_representation()[0]);
        }

        // process components
        for (let component of this.components) {
            wrapper_item.appendChild(component.html_representation()[0]);
        }

        // process artifacts
        for (let artifact of this.artifacts) {
            wrapper_item.appendChild(artifact.html_representation()[0]);
        }

        // process nodes
        for (let node of this.nodes) {
            wrapper_item.appendChild(node.html_representation()[0]);
        }

        //process packages
        for (let pack of this.packages) {
            wrapper_item.appendChild(pack.html_representation()[0]);
        }

        //process connections
        for (let connection of this.connections) {
            wrapper_item.appendChild(connection.html_representation()[0]);
        }

        return [wrapper_item];
    }

    static html_action_name() {
        return "add diagram";
    }

    static html_action_execute(parent_id) {
        addNewElement(parent_id, 'diagram');
    }
}

class Changes {
    constructor() {
        this.additions = new Set();
        this.deletions = new Set();
        this.modifications = new Set();
    }

    json_stringify() {
        let snapshots = {};
        for (let id of this.modifications) {
            if (!this.deletions.has(id) && !this.additions.has(id)) {
                snapshots[id] = elements_map[id];
            }
        }
        for (let id of this.additions) {
            if (!this.deletions.has(id)) {
                snapshots[id] = elements_map[id];
            }
        }

        return JSON.stringify({
            "add": Array.from(this.additions),
            "delete": Array.from(this.deletions),
            "changes": Array.from(this.modifications),
            snapshots
        });
    }
}

// const tags = jsonpickle.tags;
const highlight_tag = "#83c5be";

let currentGraph = null;
let elements_map = {};
let clone_elements_map = {};
let changes = new Changes();

export function getCloneElementsMapAt(id) {
    return clone_elements_map[id];
}

export function changesOperation(setType, operation, id) {
    if (setType === "additions") {
        if (operation === "has") {
            return changes.additions.has(id);
        } else if (operation === "add") {
            changes.additions.add(id);
        } else if (operation === "delete") {
            changes.additions.delete(id);
        }
    } else if (setType === "deletions") {
        if (operation === "has") {
            return changes.deletions.has(id);
        } else if (operation === "add") {
            changes.deletions.add(id);
        } else if (operation === "delete") {
            changes.deletions.delete(id);
        }
    } else if (setType === "modifications") {
        if (operation === "has") {
            return changes.modifications.has(id);
        } else if (operation === "add") {
            changes.modifications.add(id);
        } else if (operation === "delete") {
            changes.modifications.delete(id);
        }
    }
}

function addNewElement(parent_id, element_type) {
    let parent = elements_map[parent_id];
    let newElement = null;

    let requestData = JSON.stringify({ 'ids': Object.keys(elements_map) });
    let headers = {
        headers: {
            "Content-Type": "application/json;charset=UTF-8"
        }
    }

    BackendApi.get('random_md5', requestData, headers).then((res) => {
        if (res.status === 200) {
            let id = res.data;

            switch (element_type) {
                case 'connector':
                    newElement = new ConnectorUML(id, parent_id, { plantuml_connector: '-' }, '', 'text', 'text', 'SIMPLE', true);
                    parent.connections.push(newElement);
                    break;
                case 'map_property':
                    parent.changed = true;
                    newElement = new MapUMLProperty(id, parent_id, {}, 'text', 'text', true);
                    parent.properties.push(newElement);
                    break;
                case 'map':
                    newElement = new MapUML(id, parent_id, {}, 'text', [], true);
                    parent.properties.push(newElement);
                    break;
                case 'cloud':
                    newElement = new CloudUML(id, parent_id, {}, 'text', '', [], true, true);
                    parent.clouds.push(newElement);
                    break;
                case 'port':
                    newElement = new PortUML(id, parent_id, {}, '9999', true);
                    parent.ports.push(newElement);
                    break;
                case 'artifact':
                    newElement = new ArtifactUML(id, parent_id, {}, 'text', true);
                    parent.artifacts.push(newElement);
                    break;
                case 'package':
                    newElement = new PackageUML(id, parent_id, {}, 'text', [], [], [], [], true, true);
                    parent.packages.unshift(newElement);
                    break;
                case 'component_property':
                    parent.changed = true;
                    newElement = new ComponentProperty(id, parent_id, {}, 'text', 'text', true);
                    parent.properties.push(newElement);
                    break;
                case 'component':
                    newElement = new ComponentUML(id, parent_id, {}, capitalize('text'), [], null, true);
                    parent.components.unshift(newElement);
                    break;
                case 'node':
                    newElement = new NodeUML(id, parent_id, {}, capitalize('text'), [], [], [], [], true);
                    parent.nodes.unshift(newElement);
                    break;
                case 'diagram':
                    newElement = new GraphPlantUML(id, parent_id, {}, 'DeploymentDiagram', [], [], [], [], [], [], []);
                    parent.diagram = newElement;
                    break;
                default:
                    console.error("Not a valid element type!");
                    break;
            }

            if (newElement !== null) {
                elements_map[newElement.id] = newElement;
                changes.additions.add(newElement.id);
                process_graph(null, false, currentGraph);
                toggle_revert_all();
            } else {
                console.error("Could not create element of type ", element_type, "with parentID ", parent_id);
            }
        }
    }).catch((e) => {
        console.error("Error getting random md5 (", e.response.status, "): ", e.response.data);
        console.error("Could not create element of type ", element_type, "with parentID ", parent_id);
    });
}

function validate_modifications(object) {
    if (!object.equals(clone_elements_map[object.id])) {
        changes.modifications.add(object.id);
        object.changed = true;
    } else {
        changes.modifications.delete(object.id);
        object.changed = false;
    }
    delay(preview_graph, 1000);
}

export function toggle_revert_all() {
    let revert_all_button = document.getElementById('revert-all');
    if (revert_all_button === null || revert_all_button === undefined) {
        return;
    }
    if (changes.modifications.size > 0 || changes.additions.size > 0 || changes.deletions.size > 0) {
        revert_all_button.style.display = 'inline-block';
    } else {
        revert_all_button.style.display = 'none';
    }
}

function toggle_revert_icon(revert_icon, obj, property) {
    toggle_revert_all();
    let clone_obj = clone_elements_map[obj.id];
    if (clone_obj === undefined || clone_obj === null) {
        return;
    }

    if (obj[property] !== clone_obj[property]) {
        revert_icon.style.display = 'inline-block';
    } else {
        revert_icon.style.display = 'none';
    }
}

export function revert_to_original(object, text, property, html_element, revert_icon) {
    object[property] = text;
    html_element.innerText = text;
    validate_modifications(object);
    toggle_revert_icon(revert_icon, object, property);
}


export function build_graph(graph_json) {
    currentGraph = GraphPlantUML.build_from_json_object(graph_json);    
    return currentGraph;
}

export function process_graph(setPageDisplay = null, revert = false, current = null) {
    if (current !== null) currentGraph = current;
    let custom_editor = document.getElementById('editor');
    if (revert) {
        changes = new Changes();
        elements_map = {};
        clone_elements_map = {}
        isSublevel = updateGraphsStack(null, true);
    }
    let graph_elements = currentGraph.html_representation();

    let child = custom_editor.lastChild;
    while (child) {
        custom_editor.removeChild(child);
        child = custom_editor.lastChild;
    }
    graph_elements.forEach(el => {
        custom_editor.appendChild(el);
    });

    //Reload the image
    let highlightChanges = document.getElementById('highlight-checkbox').checked;
    preview_graph(highlightChanges, setPageDisplay);
}

function _findPickle(json, id) {
  if (!(typeof json === 'object' && json !== null)) return null;
  if (json.id === id) {
    return json;
  } else {
    for (let key in json) {
      if (Array.isArray(json[key])) {
        for (let i = 0; i < json[key].length; i++) {
          let result = _findPickle(json[key][i], id);
          if (result !== null) {
            return result;
          }
        }
      } else if (typeof json[key] === 'object' && json[key] !== null) {
        let result = _findPickle(json[key], id);
        if (result !== null) {
          return result;
        }
      }
    }
  }
  return null;
}

function _replacePyIdWithValue(obj, initial) {
  if (typeof obj !== 'object' || obj === null) {
    return;
  }

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key === 'py/id') {
        obj[key] = obj[key]-initial;
      } else if (typeof obj[key] === 'object') {
        _replacePyIdWithValue(obj[key], initial);
      }
    }
  }
}

function _fixPickle(json) {
  if (json === null) return null;
  const initial = json.representer.entity["py/id"];
  json = _replacePyIdWithValue(json, initial);
  return json;
}

function _filterChanges() {
  const json = JSON.parse(changes.json_stringify());
  const diagrams_types = ['ComponentDiagram', 'DeploymentDiagram', 'CompleteDiagram'];
  for (let id in json.snapshots) {
    if (id !== currentGraph.id && diagrams_types.includes(json.snapshots[id].py_class)) {
      const index = json.add.indexOf(id);
      if (index > -1) {
        json.add.splice(index, 1);
      }
    }
  }

  return JSON.stringify(json);
}

export function preview_graph(highlightChanges = null, setPageDisplay = null) {
    if (highlightChanges === null) {
        highlightChanges = document.getElementById('highlight-checkbox').checked;
    }
    
    const graph_json = _findPickle(JSON.parse(initialGraphPickle), currentGraph.id);
    _fixPickle(graph_json);
    let requestData = JSON.stringify({ 
      'pickle': graph_json ? JSON.stringify(graph_json) : null,
      'changes': graph_json ? changes.json_stringify() : _filterChanges(), 
      'highlight': highlightChanges ? highlight_tag : ''});
    let headers = {
        headers: {
            "Content-Type": "application/json;charset=UTF-8"
        }
    }

    BackendApi.post('previewgraph', requestData, headers).then((res) => {
        if (res.status === 200) {
            if(setPageDisplay !== null) {
                setPageDisplay('block');
            }
            let image = res.data;

            // setImageSrc("data:image/png;base64," + image);
            setImageSrc("data:image/svg+xml;base64," + image);
            // window.scrollTo({ top: 0, behavior: 'smooth' }); //Scroll to the top of the page
        }
    }).catch((e) => {
        if(setPageDisplay !== null) {
            //Even in case of error page should only show when image is first loaded
            setPageDisplay('block');
        }

        if (e.response.status === 414) {
            setImageSrc("");
            addAlertMessage(e.response.data, 'danger');
            cleanAllAlertMessages();
        } else if (e.response.status === 422) {
            addAlertMessage(e.response.data, 'danger');
            cleanAllAlertMessages();
        }
        else {
            console.error("Error getting preview image (", e.response.status, "): ", e.response.data);
        }
    });
}

export function submit_graph(setLoadingRequest, repoInfo) {
    let requestData = JSON.stringify({
        'repository': repoInfo.repoName,
        'branch': repoInfo.branch,
        'readme': repoInfo.readme,
        'changes': changes.json_stringify(),
        'pickle': initialGraphPickle,
    });
    let headers = {
        headers: {
            "Content-Type": "application/json;charset=UTF-8"
        }
    }

    setLoadingRequest(true);

    BackendApi.post('edit', requestData, headers).then((res) => {
        if (res.status === 200) {
            setLoadingRequest(false);
            addAlertMessage(res.data, 'success');
            cleanAllAlertMessages();
        }
    }).catch((e) => {
        if (e.response.status === 422) {
            addAlertMessage(e.response.data, 'danger');
            cleanAllAlertMessages();
        }
        else {
            console.error("Error getting preview image (", e.response.status, "): ", e.response.data);
        }

        setLoadingRequest(false);
    });


}

export function revert_all() {
    process_graph(null, true);
}

class ConnectorUML {
    constructor(id, parent_id, extra_properties, name, from_id, to_id, connector_type, changed = false) {
        this.id = id;
        this.parent_id = parent_id;
        this.extra_properties = extra_properties;
        this.name = name;
        this.from_id = from_id;
        this.to_id = to_id;
        this.connector_type = connector_type;
        this.changed = changed;
        this.py_class = 'Connector';
    }

    static build_from_json_object(json_obj) {
        let connector = new ConnectorUML(
            json_obj.id,
            json_obj.parent_id,
            json_obj.extra_properties,
            json_obj.name,
            json_obj.from_id,
            json_obj.to_id,
            json_obj.connector_type,
            json_obj._py_class
        );
        elements_map[connector.id] = connector;
        clone_elements_map[connector.id] = ConnectorUML.assign(connector);
        return connector;
    }

    static html_action_name() {
        return "add connector";
    }

    static assign(component_object) {
        return new ConnectorUML(
            component_object.id,
            component_object.parent_id,
            { ...component_object.extra_properties },
            component_object.name,
            component_object.from_id,
            component_object.to_id,
            component_object.connector_type
        );
    }

    equals(connector_obj) {
        return connector_obj instanceof ConnectorUML &&
            this.id === connector_obj.id &&
            this.parent_id === connector_obj.parent_id &&
            this.extra_properties.plantuml_connector === connector_obj.extra_properties.plantuml_connector &&
            this.name === connector_obj.name &&
            this.from_id === connector_obj.from_id &&
            this.to_id === connector_obj.to_id &&
            this.connector_type === connector_obj.connector_type;
    }

    static html_action_execute(parent_id) {
        addNewElement(parent_id, 'connector');
    }

    remove_child_and_parent_links() {
        let parent = elements_map[this.parent_id];
        let deleted_objs = [];

        delete (elements_map[this.id]);
        changes.additions.delete(this.id);
        for (let i = 0; i < parent.connections.length; i++) {
            if (parent.connections[i].id === this.id) {
                parent.connections.splice(i, 1);
            }
        }
        deleted_objs.push(this);
        return deleted_objs;
    }

    _convert(t) {
        if (t === '-d-' || t === '-u-') {
            return "SIMPLE";
        } else if (t === '-d->' || t === '-u->') {
            return "ARROW";
        } else if (t === '..d..>' || t === '..u..>') {
            return "DASHED_ARROW";
        } else {
            return "SIMPLE";
        }
    }

    html_representation() {
        let wrapper_item = get_wrapper();
        if (changes.additions.has(this.id)) {
            wrapper_item.classList.add('new-element');
        }
        let connection_item = document.createElement("li");
        connection_item.id = "uml-item";
        let edit_item = get_secondary_menu(wrapper_item, this);
        connection_item.appendChild(edit_item);


        // process from_id
        let from_text = document.createElement("var");
        from_text.contentEditable = true;
        from_text.innerText = `${this.from_id}`;
        from_text.className = 'connection-ids';
        let revert_from_icon = get_revert_icon(this, 'from_id', from_text);
        from_text.addEventListener('input', () => {
            this.from_id = from_text.innerText;
            validate_modifications(this);
            toggle_revert_icon(revert_from_icon, this, 'from_id');
        });

        let space_text_before = document.createElement("samp");
        space_text_before.innerText = " ";

        // process connector
        let connection_text = document.createElement("var");
        connection_text.contentEditable = true;
        connection_text.innerText = `${this.extra_properties.plantuml_connector}`;
        connection_text.className = 'static-connectors';
        let revert_connection_icon = get_revert_icon(this, 'plantuml_connector', connection_text);
        connection_text.addEventListener('input', () => {
            this.extra_properties.plantuml_connector = connection_text.innerText;
            this.connector_type = this._convert(this.extra_properties.plantuml_connector);
            validate_modifications(this);
            toggle_revert_icon(revert_connection_icon, this, 'plantuml_connector');
        });

        let space_text_after = document.createElement("samp");
        space_text_after.innerText = " ";

        // process to_id
        let to_text = document.createElement("var");
        to_text.contentEditable = true;
        to_text.innerText = `${this.to_id}`;
        to_text.className = 'connection-ids';
        let revert_to_icon = get_revert_icon(this, 'to_id', to_text);
        to_text.addEventListener('input', () => {
            this.to_id = to_text.innerText;
            validate_modifications(this);
            toggle_revert_icon(revert_to_icon, this, 'to_id');
        });

        // process label
        let semicolon = document.createElement("samp");
        semicolon.innerText = " : ";
        let label_def = document.createElement("var");
        label_def.contentEditable = true;
        label_def.innerText = `${this.name}`;
        label_def.className = 'aux-text';
        let revert_label_icon = get_revert_icon(this, 'name', label_def);
        label_def.addEventListener('input', () => {
            this.name = label_def.innerText;
            validate_modifications(this);
            toggle_revert_icon(revert_label_icon, this, 'name');
        });

        connection_item.appendChild(revert_from_icon);
        connection_item.appendChild(from_text);
        connection_item.appendChild(space_text_before);
        connection_item.appendChild(revert_connection_icon);
        connection_item.appendChild(connection_text);
        connection_item.appendChild(space_text_after);
        connection_item.appendChild(revert_to_icon);
        connection_item.appendChild(to_text);
        connection_item.appendChild(semicolon);
        connection_item.appendChild(revert_label_icon);
        connection_item.appendChild(label_def);

        wrapper_item.appendChild(connection_item);
        toggle_revert_icon(revert_from_icon, this, 'from_id');
        toggle_revert_icon(revert_connection_icon, this, 'plantuml_connector');
        toggle_revert_icon(revert_to_icon, this, 'to_id');
        toggle_revert_icon(revert_label_icon, this, 'name');

        return [wrapper_item];
    }
}

class MapUMLProperty {
    constructor(id, parent_id, extra_properties, key, value, visible, changed = false) {
        this.id = id;
        this.parent_id = parent_id;
        this.extra_properties = extra_properties;
        this.key = key;
        this.value = value;
        this.visible = visible;
        this.changes = changed;
        this.py_class = 'Property';
    }

    static build_from_json_object(json_obj) {
        let map_uml_property = new MapUMLProperty(
            json_obj.id,
            json_obj.parent_id,
            json_obj.extra_properties,
            json_obj.key,
            json_obj.value,
            json_obj.visible,
        );
        elements_map[map_uml_property.id] = map_uml_property;
        clone_elements_map[map_uml_property.id] = MapUMLProperty.assign(map_uml_property);
        return map_uml_property;
    }

    static assign(component_obj) {
        return new MapUMLProperty(
            component_obj.id,
            component_obj.parent_id,
            { ...component_obj.extra_properties },
            component_obj.key,
            component_obj.value,
            component_obj.visible
        );
    }

    static html_action_name() {
        return "add entry";
    }

    static html_action_execute(parent_id) {
        addNewElement(parent_id, 'map_property');
    }

    remove_child_and_parent_links() {
        let parent = elements_map[this.parent_id];
        let deleted_objs = [];

        delete (elements_map[this.id]);
        changes.additions.delete(this.id);
        for (let i = 0; i < parent.properties.length; i++) {
            if (parent.properties[i].id === this.id) {
                parent.properties.splice(i, 1);
                break;
            }
        }
        deleted_objs.push(this);
        return deleted_objs;
    }

    equals(map_uml_prop_obj) {
        return map_uml_prop_obj instanceof MapUMLProperty &&
            this.id === map_uml_prop_obj.id &&
            this.parent_id === map_uml_prop_obj.parent_id &&
            this.key === map_uml_prop_obj.key &&
            this.value === map_uml_prop_obj.value;
    }

    html_representation() {
        if (!this.visible) return [];
        let wrapper_item = get_wrapper();
        if (changes.additions.has(this.id)) {
            wrapper_item.classList.add('new-element');
        }
        let prop_item = document.createElement("li");
        prop_item.id = "uml-item";
        let edit_item = get_secondary_menu(wrapper_item, this);
        prop_item.appendChild(edit_item);
        let first_wrapper = document.createElement("span");
        first_wrapper.className = 'first';
        let key_item = document.createElement("var");
        key_item.contentEditable = true;
        key_item.innerText = `${this.key}`;
        let revert_icon_key = get_revert_icon(this, 'key', key_item);
        key_item.addEventListener('input', () => {
            this.key = key_item.innerText;
            validate_modifications(this);
            toggle_revert_icon(revert_icon_key, this, 'key');
        });

        let value_item = document.createElement("var");
        value_item.contentEditable = true;
        value_item.innerText = `${this.value}`;
        let revert_icon_value = get_revert_icon(this, 'value', value_item);
        value_item.addEventListener('input', () => {
            this.value = value_item.innerText;
            validate_modifications(this);
            toggle_revert_icon(revert_icon_value, this, 'value');
        });

        first_wrapper.appendChild(revert_icon_key);
        first_wrapper.appendChild(key_item);
        prop_item.appendChild(first_wrapper);
        add_samp_inline_text(' => ', prop_item);
        prop_item.appendChild(revert_icon_value);
        prop_item.appendChild(value_item);

        wrapper_item.appendChild(prop_item);

        toggle_revert_icon(revert_icon_key, this, 'key');
        toggle_revert_icon(revert_icon_value, this, 'value');
        return [wrapper_item];
    }
}

class MapUML {
    constructor(id, parent_id, extra_properties, name, properties, changed = false) {
        this.id = id;
        this.parent_id = parent_id;
        this.extra_properties = extra_properties
        this.name = name;
        this.properties = properties;
        this.changed = changed;
        this.py_class = 'Map';
    }

    static build_from_json_object(json_obj) {
        let props = [];
        for (let prop of json_obj.properties) {
            props.push(MapUMLProperty.build_from_json_object(prop));
        }

        let mapUML = new MapUML(json_obj.id, json_obj.parent_id, json_obj.extra_properties, json_obj.name, props);
        elements_map[mapUML.id] = mapUML;
        clone_elements_map[mapUML.id] = MapUML.assign(mapUML);
        return mapUML;
    }

    static assign(component_object) {
        let assigned_props = [];
        for (let prop of component_object.properties) {
            assigned_props.push(clone_elements_map[prop.id]);
        }
        return new MapUML(
            component_object.id,
            component_object.parent_id,
            { ...component_object.extra_properties },
            component_object.name,
            assigned_props,
        )
    }

    static html_action_name() {
        return "add map";
    }

    static html_action_execute(parent_id) {
        addNewElement(parent_id, 'map');
    }

    remove_child_and_parent_links() {
        let parent = elements_map[this.parent_id];
        let deleted_objs = [];
        while (this.properties.length > 0) {
            let prop = this.properties[this.properties.length - 1];
            prop.remove_child_and_parent_links();
            deleted_objs.push(prop);
        }

        delete (elements_map[this.id]);
        changes.additions.delete(this.id);
        for (let i = 0; i < parent.properties.length; i++) {
            if (parent.properties[i].id === this.id) {
                parent.properties.splice(i, 1);
                break;
            }
        }
        deleted_objs.push(this);
        return deleted_objs;
    }

    equals(map_obj) {
        return map_obj instanceof MapUML &&
            this.id === map_obj.id &&
            this.parent_id === map_obj.parent_id &&
            this.name === map_obj.name;
        // properties have their own changes
    }

    html_representation() {
        let wrapper_item = get_wrapper();
        if (changes.additions.has(this.id)) {
            wrapper_item.classList.add('new-element');
        }
        let map_item = document.createElement("li");
        map_item.id = "uml-item";
        let edit_item = get_secondary_menu(wrapper_item, this, [MapUMLProperty]);
        map_item.appendChild(edit_item);

        // process name
        let map_name = document.createElement("var");
        map_name.contentEditable = true;
        map_name.innerText = `${this.name}`;
        let revert_icon = get_revert_icon(this, 'name', map_name);
        map_name.addEventListener('input', () => {
            this.name = map_name.innerText;
            validate_modifications(this);
            toggle_revert_icon(revert_icon, this, 'name');
        });

        add_element_name('map', map_item);
        map_item.appendChild(revert_icon);
        map_item.appendChild(map_name);
        add_id_element(this.id, map_item);
        add_opening_bracket('{', map_item);

        wrapper_item.appendChild(map_item);

        for (let prop of this.properties) {
            let props_element = prop.html_representation();
            if (props_element.length === 0) continue;
            let prop_element = props_element[0];
            prop_element.classList.add('inner');
            wrapper_item.appendChild(prop_element);
        }

        add_closing_bracket('}', wrapper_item);
        toggle_revert_icon(revert_icon, this, 'name');
        return [wrapper_item];
    }
}

class CloudUML {
    constructor(id, parent_id, extra_properties, name, custom_name, properties, lite_node, changed = false) {
        this.id = id;
        this.parent_id = parent_id;
        this.extra_properties = extra_properties;
        this.name = name;
        this.custom_name = custom_name;
        this.properties = properties;
        this.lite_node = lite_node;
        this.changed = changed;
        this.py_class = 'Cloud';
    }

    static build_from_json_object(json_obj) {
        let maps = [];
        for (let map of json_obj.properties) {
            maps.push(MapUML.build_from_json_object(map));
        }

        let cloud = new CloudUML(
            json_obj.id,
            json_obj.parent_id,
            json_obj.extra_properties,
            json_obj.name,
            json_obj.custom_name,
            maps,
            json_obj.lite_node,
        );

        elements_map[cloud.id] = cloud;
        clone_elements_map[cloud.id] = CloudUML.assign(cloud);
        return cloud;
    }

    static assign(component_object) {
        let assigned_props = [];
        for (let prop of component_object.properties) {
            assigned_props.push(clone_elements_map[prop.id]);
        }

        return new CloudUML(
            component_object.id,
            component_object.parent_id,
            { ...component_object.extra_properties },
            component_object.name,
            component_object.custom_name,
            assigned_props,
            component_object.lite_node
        );
    }

    static html_action_name() {
        return "add network";
    }

    static html_action_execute(parent_id) {
        addNewElement(parent_id, 'cloud');
    }

    remove_child_and_parent_links() {
        let parent = elements_map[this.parent_id];
        let deleted_objs = [];
        while (this.properties.length > 0) {
            let prop = this.properties[this.properties.length - 1];
            prop.remove_child_and_parent_links();
            deleted_objs.push(prop);
        }

        delete (elements_map[this.id]);
        changes.additions.delete(this.id);
        for (let i = 0; i < parent.clouds.length; i++) {
            if (parent.clouds[i].id === this.id) {
                parent.clouds.splice(i, 1);
                break;
            }
        }
        deleted_objs.push(this);
        return deleted_objs;
    }

    equals(cloud_obj) {
        return cloud_obj instanceof CloudUML &&
            this.id === cloud_obj.id &&
            this.parent_id === cloud_obj.parent_id &&
            this.name === cloud_obj.name &&
            this.custom_name === cloud_obj.custom_name &&
            this.properties.length === cloud_obj.properties.length &&
            this.lite_node === cloud_obj.lite_node &&
            this.properties.every((p, idx) => p.equals(cloud_obj.properties[idx]));
    }

    html_representation() {
        let wrapper_item = get_wrapper();
        if (changes.additions.has(this.id)) {
            wrapper_item.classList.add('new-element');
        }
        if (this.lite_node) {
            let node_item = document.createElement("li");
            node_item.id = "uml-item";
            let edit_node_item = get_secondary_menu(wrapper_item, this);
            let node_name = document.createElement("var");
            node_name.contentEditable = true;
            node_name.innerText = `${this.name}`;
            let revert_icon = get_revert_icon(this, 'name', node_name);
            node_name.addEventListener('input', () => {
                this.name = node_name.innerText;
                validate_modifications(this);
                toggle_revert_icon(revert_icon, this, 'name');
            });
            let network_identifier = document.createElement("samp");
            network_identifier.innerText = ` <<network>>`;
            network_identifier.className = 'aux-text';

            node_item.appendChild(edit_node_item);
            add_element_name('node', node_item);
            node_item.appendChild(revert_icon);
            add_samp_inline_text('"', node_item);
            node_item.appendChild(node_name);
            add_samp_inline_text('" ', node_item)
            node_item.appendChild(network_identifier);
            add_id_element(this.id, node_item);

            wrapper_item.appendChild(node_item);
            toggle_revert_icon(revert_icon, this, 'name');

            return [wrapper_item];
        }

        let cloud_item = document.createElement("li");
        cloud_item.id = "uml-item";
        let edit_item = get_secondary_menu(wrapper_item, this, [MapUML]);
        let cloud_name = document.createElement("var");
        cloud_name.contentEditable = true;
        cloud_name.innerText = `${this.name}`;
        let revert_name_icon = get_revert_icon(this, 'name', cloud_name);
        cloud_name.addEventListener('input', () => {
            this.name = cloud_name.innerText;
            validate_modifications(this);
            toggle_revert_icon(revert_name_icon, this, 'name');
        });
        let custom_name = document.createElement("var");
        custom_name.contentEditable = true;
        if (this.custom_name) {
            custom_name.innerText = `${this.custom_name}`;
        } else {
            custom_name.innerText = `placeholder`
        }
        let revert_custom_name_icon = get_revert_icon(this, 'custom_name', custom_name);
        custom_name.addEventListener('input', () => {
            this.custom_name = custom_name.innerText;
            validate_modifications(this);
            toggle_revert_icon(revert_custom_name_icon, this, 'custom_name');
        });

        cloud_item.appendChild(edit_item);
        add_element_name('cloud', cloud_item);
        cloud_item.appendChild(revert_name_icon);
        add_samp_inline_text('"', cloud_item);
        cloud_item.appendChild(cloud_name);
        add_samp_inline_text('" ', cloud_item);
        cloud_item.appendChild(revert_custom_name_icon);
        add_samp_inline_text('<<', cloud_item);
        cloud_item.appendChild(custom_name);
        add_samp_inline_text('>>', cloud_item)
        add_id_element(this.id, cloud_item);
        add_opening_bracket('{', cloud_item);

        wrapper_item.appendChild(cloud_item);

        for (let map of this.properties) {
            let map_element = map.html_representation()[0];
            map_element.classList.add('inner');
            wrapper_item.appendChild(map_element);
        }

        add_closing_bracket('}', wrapper_item);

        toggle_revert_icon(revert_name_icon, this, 'name');
        toggle_revert_icon(revert_custom_name_icon, this, 'custom_name');

        return [wrapper_item];
    }

}

class PortUML {
    constructor(id, parent_id, extra_properties, name, changed = false) {
        this.id = id;
        this.parent_id = parent_id;
        this.extra_properties = extra_properties;
        this.name = name;
        this.changed = changed;
        this.py_class = 'Port';
    }

    static build_from_json_object(json_obj) {
        let port = new PortUML(json_obj.id, json_obj.parent_id, json_obj.extra_properties, json_obj.name);
        elements_map[port.id] = port;
        clone_elements_map[port.id] = PortUML.assign(port);
        return port;
    }

    static assign(object) {
        return new PortUML(
            object.id,
            object.parent_id,
            { ...object.extra_properties },
            object.name
        );
    }

    static html_action_name() {
        return "add port";
    }

    static html_action_execute(parent_id) {
        addNewElement(parent_id, 'port');
    }

    remove_child_and_parent_links() {
        let parent = elements_map[this.parent_id];
        let deleted_objs = [];
        delete (elements_map[this.id]);
        changes.additions.delete(this.id);
        for (let i = 0; i < parent.ports.length; i++) {
            if (parent.ports[i].id === this.id) {
                parent.ports.splice(i, 1);
                break;
            }
        }
        deleted_objs.push(this);
        return deleted_objs;
    }

    equals(obj) {
        return obj instanceof PortUML &&
            this.id === obj.id &&
            this.parent_id === obj.parent_id &&
            this.name === obj.name;
    }

    html_representation() {
        let wrapper_item = get_wrapper();
        if (changes.additions.has(this.id)) {
            wrapper_item.classList.add('new-element');
        }
        let item = document.createElement("li");
        item.id = "uml-item";
        let edit_item = get_secondary_menu(wrapper_item, this);
        let name = document.createElement("var");
        name.contentEditable = true;
        name.innerText = `${this.name}`;
        let revert_icon = get_revert_icon(this, 'name', name);
        name.addEventListener('input', () => {
            this.name = name.innerText;
            validate_modifications(this);
            toggle_revert_icon(revert_icon, this, 'name');
        });

        item.appendChild(edit_item);
        add_element_name('()', item);
        item.appendChild(revert_icon);
        add_samp_inline_text('"', item);
        item.appendChild(name);
        add_samp_inline_text('"', item);
        add_id_element(this.id, item);
        wrapper_item.appendChild(item);

        toggle_revert_icon(revert_icon, this, 'name');

        return [wrapper_item];
    }
}

class ArtifactUML {
    constructor(id, parent_id, extra_properties, name, changed = false) {
        this.id = id;
        this.parent_id = parent_id;
        this.extra_properties = extra_properties;
        this.name = name;
        this.changed = changed;
        this.py_class = 'Artifact';
    }

    static build_from_json_object(json_obj) {
        let artifact = new ArtifactUML(json_obj.id, json_obj.parent_id, json_obj.extra_properties, json_obj.name);
        elements_map[artifact.id] = artifact;
        clone_elements_map[artifact.id] = ArtifactUML.assign(artifact);
        return artifact;
    }

    static assign(obj) {
        return new ArtifactUML(
            obj.id,
            obj.parent_id,
            { ...obj.extra_properties },
            obj.name
        );
    }

    static html_action_name() {
        return "add artifact";
    }

    static html_action_execute(parent_id) {
        addNewElement(parent_id, 'artifact');
    }

    remove_child_and_parent_links() {
        let parent = elements_map[this.parent_id];
        let deleted_objs = [];
        delete (elements_map[this.id]);
        changes.additions.delete(this.id);
        for (let i = 0; i < parent.artifacts.length; i++) {
            if (parent.artifacts[i].id === this.id) {
                parent.artifacts.splice(i, 1);
                break;
            }
        }
        deleted_objs.push(this);
        return deleted_objs;
    }

    equals(obj) {
        return obj instanceof ArtifactUML &&
            this.id === obj.id &&
            this.parent_id === obj.parent_id &&
            this.name === obj.name;
    }

    html_representation() {
        let wrapper_item = get_wrapper();
        if (changes.additions.has(this.id)) {
            wrapper_item.classList.add('new-element');
        }
        let artifact_item = document.createElement("li");
        artifact_item.id = "uml-item";
        let edit_item = get_secondary_menu(wrapper_item, this);
        let artifact_name = document.createElement("var");
        artifact_name.contentEditable = true;
        artifact_name.innerText = `${this.name}`;
        let revert_icon = get_revert_icon(this, 'name', artifact_name);
        artifact_name.addEventListener('input', () => {
            this.name = artifact_name.innerText;
            validate_modifications(this);
            toggle_revert_icon(revert_icon, this, 'name');
        });

        artifact_item.appendChild(edit_item);
        add_element_name('artifact', artifact_item);
        artifact_item.appendChild(revert_icon);
        add_samp_inline_text('"', artifact_item);
        artifact_item.appendChild(artifact_name);
        add_samp_inline_text('" ', artifact_item);
        add_id_element(this.id, artifact_item);
        wrapper_item.appendChild(artifact_item);

        toggle_revert_icon(revert_icon, this, 'name');

        return [wrapper_item];
    }

}

class PackageUML {
    constructor(id, parent_id, extra_properties, name, artifacts, ports, components, packages, full_representation, changed = false) {
        this.id = id;
        this.parent_id = parent_id;
        this.extra_properties = extra_properties;
        this.name = name;
        this.artifacts = artifacts;
        this.ports = ports;
        this.components = components;
        this.packages = packages;
        this.full_representation = full_representation;
        this.changed = changed;
        this.py_class = 'Package';
    }

    static build_from_json_object(json_obj) {
        let artifacts = [];
        for (let artifact of json_obj.artifacts) {
            artifacts.push(ArtifactUML.build_from_json_object(artifact));
        }

        let ports = [];
        for (let port of json_obj.ports) {
            ports.push(PortUML.build_from_json_object(port));
        }

        let components = [];
        for (let component of json_obj.components) {
            components.push(ComponentUML.build_from_json_object(component));
        }

        let packages = [];
        for (let pckg of json_obj.packages) {
            packages.push(PackageUML.build_from_json_object(pckg));
        }

        let package_uml = new PackageUML(
            json_obj.id,
            json_obj.parent_id,
            json_obj.extra_properties,
            json_obj.name,
            artifacts,
            ports,
            components,
            packages,
            json_obj.full_representation,
        );

        elements_map[package_uml.id] = package_uml;
        clone_elements_map[package_uml.id] = PackageUML.assign(package_uml);
        return package_uml
    }

    static assign(obj) {
        let clone_ports = [];
        let clone_artifacts = [];
        let clone_components = [];
        let clone_packages = [];

        for (let port of obj.ports) {
            clone_ports.push(clone_elements_map[port.id]);
        }

        for (let artifact of obj.artifacts) {
            clone_artifacts.push(clone_elements_map[artifact.id]);
        }

        for (let component of obj.components) {
            clone_components.push(clone_elements_map[component.id]);
        }

        for (let pckg of obj.packages) {
            clone_packages.push(clone_elements_map[pckg.id]);
        }

        return new PackageUML(
            obj.id,
            obj.parent_id,
            { ...obj.extra_properties },
            obj.name,
            clone_artifacts,
            clone_ports,
            clone_components,
            clone_packages,
            obj.full_representation
        );
    }

    static html_action_name() {
        return "add package";
    }

    static html_action_execute(parent_id) {
        addNewElement(parent_id, 'package');
    }

    remove_child_and_parent_links() {
        let parent = elements_map[this.parent_id];
        let deleted_objs = [];
        while (this.artifacts.length > 0) {
            let artifact = this.artifacts[this.artifacts.length - 1];
            artifact.remove_child_and_parent_links();
            deleted_objs.push(artifact);
        }

        while (this.ports.length > 0) {
            let port = this.ports[this.ports.length - 1];
            port.remove_child_and_parent_links();
            deleted_objs.push(port);
        }

        while (this.components.length > 0) {
            let component = this.components[this.components.length - 1];
            component.remove_child_and_parent_links();
            deleted_objs.push(component);
        }

        while (this.packages.length > 0) {
            let pckg = this.packages[this.packages.length - 1];
            pckg.remove_child_and_parent_links();
            deleted_objs.push(pckg);
        }

        delete (elements_map[this.id]);
        changes.additions.delete(this.id);
        for (let i = 0; i < parent.packages.length; i++) {
            if (parent.packages[i].id === this.id) {
                parent.packages.splice(i, 1);
                break;
            }
        }
        deleted_objs.push(this);
        return deleted_objs;
    }

    equals(obj) {
        return obj instanceof PackageUML &&
            this.id === obj.id &&
            this.parent_id === obj.parent_id &&
            this.name === obj.name;
        // not comparing artifacts and ports cause these elements keep track of their changes
    }

    html_representation() {
        let wrapper_item = get_wrapper();
        if (changes.additions.has(this.id)) {
            wrapper_item.classList.add('new-element');
        }
        let revert_name_icon = document.createElement('div');
        if (this.full_representation) {
            let package_item = document.createElement("li");
            package_item.id = "uml-item";
            let edit_item = get_secondary_menu(wrapper_item, this, [ArtifactUML, PortUML, PackageUML, ComponentUML]);
            let package_name = document.createElement("var");
            package_name.contentEditable = true;
            package_name.innerText = `${this.name}`
            revert_name_icon = get_revert_icon(this, 'name', package_name);
            package_name.addEventListener('input', () => {
                this.name = package_name.innerText;
                validate_modifications(this);
                toggle_revert_icon(revert_name_icon, this, 'name');
            });
            let package_id = document.createElement("samp");
            package_id.innerText = `" as ${this.id} {`;

            package_item.appendChild(edit_item);
            add_element_name('package', package_item);
            package_item.appendChild(revert_name_icon);
            add_samp_inline_text('"', package_item);
            package_item.appendChild(package_name);
            add_samp_inline_text('" ', package_item);
            add_id_element(this.id, package_item);
            add_opening_bracket('{', package_item);
            wrapper_item.appendChild(package_item);
        }

        for (let artifact of this.artifacts) {
            let artifact_element = artifact.html_representation()[0];
            if (this.full_representation) {
                artifact_element.classList.add('inner');
            }
            wrapper_item.appendChild(artifact_element);
        }

        for (let port of this.ports) {
            let port_element = port.html_representation()[0];
            if (this.full_representation) {
                port_element.classList.add('inner');
            }
            wrapper_item.appendChild(port_element);
        }

        for (let component of this.components) {
            let component_element = component.html_representation()[0];
            if (this.full_representation) {
                component_element.classList.add('inner');
            }
            wrapper_item.appendChild(component_element);
        }

        for (let pckg of this.packages) {
            let package_element = pckg.html_representation()[0];
            if (this.full_representation) {
                package_element.classList.add('inner');
            }
            wrapper_item.appendChild(package_element);
        }

        if (this.full_representation) {
            add_closing_bracket('}', wrapper_item);
            toggle_revert_icon(revert_name_icon, this, 'name');
        }

        return [wrapper_item];
    }

}

class ComponentProperty {
    constructor(id, parent_id, extra_properties, key, value, visible, changed = false) {
        this.id = id;
        this.parent_id = parent_id;
        this.extra_properties = extra_properties;
        this.key = key;
        this.value = value;
        this.visible = visible;
        this.changed = changed;
        this.py_class = 'Property';
    }

    static build_from_json_object(json_obj) {
        let comp_prop = new ComponentProperty(
            json_obj.id,
            json_obj.parent_id,
            json_obj.extra_properties,
            json_obj.key,
            json_obj.value,
            json_obj.visible
        );
        elements_map[comp_prop.id] = comp_prop;
        clone_elements_map[comp_prop.id] = ComponentProperty.assign(comp_prop);
        return comp_prop;
    }

    static assign(obj) {
        return new ComponentProperty(
            obj.id,
            obj.parent_id,
            { ...obj.extra_properties },
            obj.key,
            obj.value,
            obj.visible
        );
    }

    static html_action_name() {
        return "add property";
    }

    static html_action_execute(parent_id) {
        addNewElement(parent_id, 'component_property');
    }

    remove_child_and_parent_links() {
        let parent = elements_map[this.parent_id];
        let deleted_objs = [];
        delete (elements_map[this.id]);
        changes.additions.delete(this.id);
        for (let i = 0; i < parent.properties.length; i++) {
            if (parent.properties[i].id === this.id) {
                parent.properties.splice(i, 1);
                break;
            }
        }
        deleted_objs.push(this);
        return deleted_objs;
    }

    equals(obj) {
        return obj instanceof ComponentProperty &&
            this.id === obj.id &&
            this.parent_id === obj.parent_id &&
            this.key === obj.key &&
            this.value === obj.value;
    }

    html_representation() {
        if (!this.visible) return [];
        let wrapper_item = get_wrapper();
        if (changes.additions.has(this.id)) {
            wrapper_item.classList.add('new-element');
        }
        let prop_item = document.createElement("li");
        prop_item.id = "uml-item";
        let edit_item = get_secondary_menu(wrapper_item, this);
        prop_item.appendChild(edit_item);
        let first_wrapper = document.createElement("span");
        first_wrapper.className = 'first';
        let key_edit = document.createElement("var");
        key_edit.contentEditable = true;
        key_edit.innerText = `${this.key}`;
        let revert_key_icon = get_revert_icon(this, 'key', key_edit);
        key_edit.addEventListener('input', () => {
            this.key = key_edit.innerText;
            validate_modifications(this);
            toggle_revert_icon(revert_key_icon, this, 'key');
        });

        let value = document.createElement("var");
        value.contentEditable = true;
        value.innerText = `${this.value}`;
        let revert_value_icon = get_revert_icon(this, 'value', value);
        value.addEventListener('input', () => {
            this.value = value.innerText;
            validate_modifications(this);
            toggle_revert_icon(revert_value_icon, this, 'value');
        });


        first_wrapper.appendChild(revert_key_icon);
        first_wrapper.appendChild(key_edit);
        prop_item.appendChild(first_wrapper);
        add_samp_inline_text(': ', prop_item);
        prop_item.appendChild(revert_value_icon);
        prop_item.appendChild(value);

        wrapper_item.appendChild(prop_item);

        toggle_revert_icon(revert_key_icon, this, 'key');
        toggle_revert_icon(revert_value_icon, this, 'value');

        return [wrapper_item];
    }
}

class ComponentUML {
    constructor(id, parent_id, extra_properties, name, properties, diagram, changed = false) {
        this.id = id;
        this.parent_id = parent_id;
        this.extra_properties = extra_properties;
        this.name = name;
        this.properties = properties;
        this.diagram = diagram;
        this.changed = changed;
        this.py_class = 'Component';
    }

    static build_from_json_object(json_obj) {
        let properties = [];
        for (let prop of json_obj.properties) {
            let property = ComponentProperty.build_from_json_object(prop);
            properties.push(property);
        }
        let diagram = json_obj.diagram != null ? GraphPlantUML.build_from_json_object(json_obj.diagram) : null;

        let component = new ComponentUML(
            json_obj.id,
            json_obj.parent_id,
            json_obj.extra_properties,
            capitalize(json_obj.name),
            properties,
            diagram,
            json_obj._py_class);
        elements_map[component.id] = component;
        clone_elements_map[component.id] = ComponentUML.assign(component);
        return component;
    }

    static assign(obj) {
        let props = [];
        for (let prop of obj.properties) {
            props.push(clone_elements_map[prop.id]);
        }

        return new ComponentUML(
            obj.id,
            obj.parent_id,
            { ...obj.extra_properties },
            obj.name,
            props,
            obj.diagram
        );
    }

    static html_action_name() {
        return "add component";
    }

    static html_action_execute(parent_id) {
        addNewElement(parent_id, 'component');
    }

    remove_child_and_parent_links() {
        let parent = elements_map[this.parent_id];
        let deleted_objs = [];
        while (this.properties.length > 0) {
            let prop = this.properties[this.properties.length - 1];
            prop.remove_child_and_parent_links();
            deleted_objs.push(prop);
        }
        delete (elements_map[this.id]);
        changes.additions.delete(this.id);
        for (let i = 0; i < parent.components.length; i++) {
            if (parent.components[i].id === this.id) {
                parent.components.splice(i, 1);
                break;
            }
        }
        deleted_objs.push(this);
        return deleted_objs;
    }

    equals(obj) {
        return obj instanceof ComponentUML &&
            this.id === obj.id &&
            this.parent_id === obj.parent_id &&
            this.name === obj.name;
        // properties have their own changes
    }

    html_representation() {
        let wrapper_item = get_wrapper();
        if (changes.additions.has(this.id)) {
            wrapper_item.classList.add('new-element');
        }
        let component_item = document.createElement("li");
        component_item.id = "uml-item";
        const diagrams = this.diagram ? [] : [GraphPlantUML];
        let edit_item = get_secondary_menu(wrapper_item, this, [ComponentProperty, ...diagrams]);

        component_item.appendChild(edit_item);
        add_element_name('component', component_item);
        add_id_element(this.id, component_item, false);
        add_opening_bracket('[', component_item);
        if (this.diagram) {
          add_get_inside(component_item, () => {
              isSublevel = updateGraphsStack(this.diagram);
          });
        }

        let name_item = document.createElement("li");
        name_item.id = "uml-item";
        let first_wrapper = document.createElement("span");
        first_wrapper.className = 'inner-name';
        let name_text = document.createElement("var");
        name_text.contentEditable = true;
        name_text.innerText = `${capitalize(this.name)}`;

        let revert_icon = get_revert_icon(this, 'name', name_text);
        name_text.addEventListener('input', () => {
            this.name = name_text.innerText;
            validate_modifications(this);
            toggle_revert_icon(revert_icon, this, 'name');
        });

        first_wrapper.appendChild(revert_icon);
        first_wrapper.appendChild(name_text)
        name_item.appendChild(first_wrapper);

        wrapper_item.appendChild(component_item);
        wrapper_item.appendChild(name_item);

        for (let prop of this.properties) {
            let props_element = prop.html_representation();
            if (props_element.length === 0) continue;
            let prop_element = props_element[0];
            prop_element.classList.add('inner');
            wrapper_item.appendChild(prop_element);
        }

        add_closing_bracket(']', wrapper_item);

        toggle_revert_icon(revert_icon, this, 'name');

        return [wrapper_item];
    }
}

class NodeUML {
    constructor(
        id, parent_id, extra_properties, name, components, in_ports, artifacts, packages, changed = false
    ) {
        this.id = id;
        this.parent_id = parent_id;
        this.extra_properties = extra_properties;
        this.name = name;
        this.components = components;
        this.ports = in_ports;
        this.artifacts = artifacts;
        this.packages = packages;
        this.changed = changed;
        this.py_class = 'Node';
    }

    static build_from_json_object(json_obj) {
        let artifacts = [];
        let in_ports = [];
        let components = [];
        let packages = [];
        for (let art_obj of json_obj.artifacts) {
            artifacts.push(ArtifactUML.build_from_json_object(art_obj));
        }
        for (let ip of json_obj.in_ports) {
            in_ports.push(PortUML.build_from_json_object(ip));
        }
        for (let comp of json_obj.components) {
            components.push(ComponentUML.build_from_json_object(comp));
        }
        for (let pckg of json_obj.packages) {
            packages.push(PackageUML.build_from_json_object(pckg))
        }

        let node = new NodeUML(
            json_obj.id,
            json_obj.parent_id,
            json_obj.extra_properties,
            capitalize(json_obj.name),
            components,
            in_ports,
            artifacts,
            packages,
        );

        elements_map[node.id] = node;
        clone_elements_map[node.id] = NodeUML.assign(node);
        return node;
    }

    static assign(obj) {
        let components = [];
        let ports = [];
        let artifacts = [];
        let packages = [];

        for (let comp of obj.components) {
            components.push(clone_elements_map[comp.id]);
        }

        for (let port of obj.ports) {
            ports.push(clone_elements_map[port.id]);
        }

        for (let artifact of obj.artifacts) {
            artifacts.push(clone_elements_map[artifact.id]);
        }

        for (let pckg of obj.packages) {
            packages.push(clone_elements_map[pckg.id]);
        }

        return new NodeUML(
            obj.id,
            obj.parent_id,
            { ...obj.extra_properties },
            obj.name,
            components,
            ports,
            artifacts,
            packages
        )
    }

    static html_action_name() {
        return "add node";
    }

    static html_action_execute(parent_id) {
        addNewElement(parent_id, 'node');
    }

    remove_child_and_parent_links() {
        let parent = elements_map[this.parent_id];
        let deleted_objs = [];
        while (this.components.length > 0) {
            let comp = this.components[this.components.length - 1];
            comp.remove_child_and_parent_links();
            deleted_objs.push(comp);
        }

        while (this.ports.length > 0) {
            let port = this.ports[this.ports.length - 1]
            port.remove_child_and_parent_links();
            deleted_objs.push(port);
        }

        while (this.artifacts.length > 0) {
            let artifact = this.artifacts[this.artifacts.length - 1];
            artifact.remove_child_and_parent_links();
            deleted_objs.push(artifact);
        }

        while (this.packages.length > 0) {
            let pckg = this.packages[this.packages.length - 1];
            pckg.remove_child_and_parent_links();
            deleted_objs.push(pckg)
        }

        delete (elements_map[this.id]);
        changes.additions.delete(this.id);
        for (let i = 0; i < parent.nodes.length; i++) {
            if (parent.nodes[i].id === this.id) {
                parent.nodes.splice(i, 1);
                break;
            }
        }
        deleted_objs.push(this);
        return deleted_objs;
    }


    equals(obj) {
        return obj instanceof NodeUML &&
            this.id === obj.id &&
            this.parent_id === obj.parent_id &&
            this.name === obj.name;
        // Not checking others cause they have their changes
    }

    html_representation() {
        let wrapper_item = get_wrapper();
        if (changes.additions.has(this.id)) {
            wrapper_item.classList.add('new-element');
        }
        let node_item = document.createElement("li");
        node_item.id = "uml-item";
        let edit_item = get_secondary_menu(
            wrapper_item,
            this,
            [ComponentUML, ArtifactUML, PortUML, PackageUML]
        );
        let post_node_text = document.createElement("samp");
        post_node_text.innerText = ` "`;
        let node_name = document.createElement("var");
        node_name.contentEditable = true;
        node_name.innerText = `${capitalize(this.name)}`;
        let revert_icon = get_revert_icon(this, 'name', node_name);
        node_name.addEventListener('input', () => {
            this.name = node_name.innerText;
            validate_modifications(this);
            toggle_revert_icon(revert_icon, this, 'name');
        });


        node_item.appendChild(edit_item);
        add_element_name('node', node_item);
        node_item.appendChild(revert_icon);
        add_samp_inline_text('"', node_item)
        node_item.appendChild(node_name);
        add_samp_inline_text('" ', node_item)
        add_id_element(this.id, node_item);
        add_opening_bracket('{', node_item);

        wrapper_item.appendChild(node_item);

        for (let component of this.components) {
            let component_element = component.html_representation()[0];
            component_element.classList.add('inner');
            wrapper_item.appendChild(component_element);
        }

        for (let artifact of this.artifacts) {
            let artifact_element = artifact.html_representation()[0];
            artifact_element.classList.add('inner');
            wrapper_item.appendChild(artifact_element);
        }

        for (let port of this.ports) {
            let port_element = port.html_representation()[0];
            port_element.classList.add('inner');
            wrapper_item.appendChild(port_element);
        }

        for (let package_uml of this.packages) {
            let package_element = package_uml.html_representation()[0];
            package_element.classList.add('inner');
            wrapper_item.appendChild(package_element);
        }
        add_closing_bracket('}', wrapper_item);

        toggle_revert_icon(revert_icon, this, 'name');
        return [wrapper_item];
    }
}