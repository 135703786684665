import infra_logo from '../assets/infragenie_logo.png';

function NavBar() {
    let isHomePage = (window.location.pathname === '/');

    return (
        <nav className="navbar navbar-expand-lg bg-light shadow-sm mb-5">
            <div className="container navbar">
                <h1 className="p-3 ms-3 display-6-imp">
                    <a className='link-underline link-underline-opacity-0' href='/'>
                        <img src={infra_logo} className="pe-2 text-secondary" width={200} height={50} alt="logo"/>
                    </a>
                </h1>
                {!isHomePage ? 
                    <div className="text-right text-muted">
                        <a className="text-secondary link-underline link-underline-opacity-0" href="/" target="_blank">
                            <i className="bi bi-info-circle-fill pe-2"></i>More info
                        </a>
                    </div> :
                    <></>
                }
            </div>
        </nav>
    );
}
export default NavBar;