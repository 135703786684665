import { useEffect, useRef, useState } from 'react';
import RepoDetailsForm from '../components/RepoDetailsForm';
import BackendApi from '../BackendApi';
import { build_graph, process_graph, preview_graph, submit_graph, setUpdateGraphsStack, setInitialGraph, setImageSrcSetter } from '../plantUML/plantUML';
import { TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch";
import './PlantUMLEditor.css';

export function updatePreviewPlantUML(highlightChanges) {
  preview_graph(highlightChanges);
}

export function submitPlantUMLGraph(setLoadingRequest, repoInfo) {
  submit_graph(setLoadingRequest, repoInfo);
}

function base64ToBinary(base64) {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

function openImage(src) {
  const base64Svg = src.split(',', 2)[1];
  const binarySvg = base64ToBinary(base64Svg);

  const blob = new Blob([binarySvg], { type: "image/svg+xml" });
  const url = URL.createObjectURL(blob);
  
  const win = window.open(url);
  win.onload = (evt) => URL.revokeObjectURL(url);
}

function PlantUMLEditor() {
  
  const searchParams = new URL(window.location.href).searchParams;
  const repoName = searchParams.get('repo');
  const branch = searchParams.get('branch');
  const readme = searchParams.get('readme');
  
  const [pageDisplay, setPageDisplay] = useState('none');
  const [currentGraph, setCurrentGraph] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const graphsStack = useRef(null);
  const initialGraphPickle = useRef(null);
  const initialGraphJson = useRef(null);

  useEffect(() => {
    if (graphsStack.current === null) {
      BackendApi.get('graph/plantuml', { params: { repo: repoName, branch: branch } }).then((res) => {
        if (res.status === 200) {
          initialGraphJson.current = res.data.json;
          initialGraphPickle.current = res.data.pickle;
          setInitialGraph(initialGraphPickle.current);
          const graph = build_graph(initialGraphJson.current);
          graphsStack.current = [graph];
          setCurrentGraph(graph);
        }

      }).catch((err) => {
        if(err.response.status !== 303) {
          console.error("Error getting graph ("+ err.response.status +"): ", err.response.data);
        }
        window.location.href = '/bad_repo';
      });
    } else if (graphsStack.current[0] != null) {
      setInitialGraph(initialGraphPickle.current);
      setImageSrcSetter(setImageSrc);
      setUpdateGraphsStack((newGraph, clean) => {
          if (clean) {
            const graph = build_graph(initialGraphJson.current);
            graphsStack.current = [graph];
            setCurrentGraph(graph);
            return false;
          }
          const newStack = newGraph != null ? [newGraph, ...graphsStack.current] : graphsStack.current.slice(1);
          const isSublevel = newStack.length > 1;
          graphsStack.current = newStack;
          setCurrentGraph(newStack[0]);
          return isSublevel;
      });
      process_graph(setPageDisplay, null, currentGraph);
      window.scrollTo({ top: 0, behavior: 'smooth' }); //Scroll to the top of the page
    }
  }, [currentGraph, repoName, branch, readme]);

  const Controls = ({src}) => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    resetTransform();
    return (
      <>
        <button onClick={() => zoomIn()}><i className="bi bi-zoom-in"></i></button>
        <button onClick={() => zoomOut()}><i className="bi bi-zoom-out"></i></button>
        <button onClick={() => resetTransform()}><i className="bi bi-arrow-clockwise"></i></button>
        <button onClick={() => openImage(src)}><i className="bi bi-eye"></i></button>
      </>
    );
  };

  return (
    <>
      <div className='container text-center mx-auto' id="loader" style={{ display: pageDisplay === "none" ? "block" : "none", paddingTop: "300px", paddingBottom: "300px" }} >
        <div className="spinner-grow" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
      <div className="text-start mx-5" id="editor-page" style={{display: pageDisplay}}>
        <div className='row' style={{marginLeft: "5rem", marginRight: "5rem"}}>
          <h3 className="text-primary mb-3">
            Update the diagram with <a href="https://plantuml.com/" target="_blank" rel="noreferrer">plantuml</a>
          </h3>
          <p className="text-secondary mb-0">
            Use the menu icon <i className="bi bi-grid-3x2-gap-fill" /> to add and delete elements.
            Click on hovered text to change names and attributes.
          </p>
          <p className="text-secondary mb-0">
            <span style={{ backgroundColor: '#83c5be80' }}>New elements</span> will appear with a different background color.
          </p>
          <p className="text-secondary">
            Use the revert icon <i className="bi bi-arrow-counterclockwise" /> to revert your changes.
          </p>
        </div>
        <div className='row'>
          <div id="image-viewer" className='col-xl-6 sticky-xl-top order-xl-last shadow p-3 bg-body rounded'>
            <TransformWrapper>
              <div id="image-viewer-controls">
                <Controls src={imageSrc} />
              </div>
              <TransformComponent>
                <img src={imageSrc} alt="Unable to generate" style={{width: "100%"}}/>
              </TransformComponent>
            </TransformWrapper>
          </div>

          <div id="editor-container" className="col-xl-6 order-xl-first shadow p-3 bg-body rounded" >
            <ol id="editor">
            </ol>
          </div>
        </div>
        <RepoDetailsForm className="row"
          formTitle="Add the updated diagram to your repository"
          inEditorPage={true}
          leftBtnText="Submit changes"
          activeEditor="plantuml"
          repoName={repoName}
          branch={branch}
          readme={readme}
        />
      </div>
    </>
  );
}
export default PlantUMLEditor;