function ButtonLink(props){
    return (
        <button type='button' className={props.classes} onClick={() => {
            window.location.href = props.link;
        }}
        style={{ width: '180px', height: '40px'}}>
            {props.btnText}
            {props.icon ? <i className={props.icon}></i> : ''}
          </button>
    );
} 
export default ButtonLink;

