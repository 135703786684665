import { useCallback, useState, useEffect } from "react";
import { addAlertMessage, capitalize, cleanAllAlertMessages } from "../utils/utils";

function EditMenuVisual(props) {
    let graph = props.graph;
    //let setGraph = props.setGraph; //TODO: Use this for delete elements and links (?) -> Maybe not needed
    let changes = props.changes;
    let setChanges = props.setChanges;

    const [selectedElement, setSelectedElement] = useState(null); //TODO: Add highlight to selected elem or link
    const [newElemName, setNewElemName] = useState("");
    const [selectedElemChildren, setSelectedElemChildren] = useState([]);
    const [selectedLink, setSelectedLink] = useState(null);
    const [selectedLinkSrc, setSelectedLinkSrc] = useState(null)
    const [selectedLinkTgt, setSelectedLinkTgt] = useState(null);

    const openElemProperties = useCallback(
        (id) => {
            let element = graph.findElement(id);
            console.log("Element Clicked:", element);

            setSelectedElement(element);

            if (element !== null) {
                setNewElemName(element.name);

                let children = graph.findElementChildren(element.id);
                console.log("Element Children:", children);

                setSelectedElemChildren(children);
            }
        }, [graph]
    );

    const openLinkProperties = useCallback(
        (id) => {
            let link = graph.findLink(id);
            console.log("Link Clicked:", link);

            setSelectedLink(link);
        
            if (link.source !== null && link.source !== undefined) {
                setSelectedLinkSrc(graph.findElement(link.source.id));
            }

            if (link.target !== null && link.target !== undefined) {
                setSelectedLinkTgt(graph.findElement(link.target.id));
            }
        }, [graph]
    );

    const handleClick = useCallback(
        (e) => {
            if (e.detail !== null && e.detail === 2) {
                let cellsSelected = graph.maxGraph.getSelectionCells();
                console.log("Double clicked on: ", cellsSelected[0]);
                
                if (cellsSelected.length === 1) {
                    let cell = cellsSelected[0];
                    let id = cell.id;
                    let isLink = cell.edge;

                    if (isLink) {
                        openLinkProperties(id);
                    } else {
                        openElemProperties(id);
                    }    
                }

            }
        },
        [graph, openElemProperties, openLinkProperties]
    );

    const handleContextMenuOpen = useCallback(
        (e) => {
            console.log("Context menu event on:", e.target);
            let cellsSelected = graph.maxGraph.getSelectionCells();
            console.log(cellsSelected);
        },
        [graph]
    );


    useEffect(() => {
        document.addEventListener("click", handleClick);
        document.addEventListener("contextmenu", handleContextMenuOpen);
        return () => {
            document.removeEventListener("click", handleClick);
            document.removeEventListener("contextmenu", handleContextMenuOpen);
        }
    }, [handleClick, handleContextMenuOpen]);


    const handleClickElem = useCallback(
        (e) => {
            let textList = e.target.innerText.split(" ");
            let id = textList[textList.length - 1];

            openElemProperties(id);
        }, [openElemProperties]
    );

    const handleClickLink = useCallback(
        (e) => {
            let textList = e.target.innerText.split(" ");
            let id = textList.length < 2 ? textList[0] : textList[textList.length - 1];

            openLinkProperties(id);
        }, [openLinkProperties]
    );

    const hasNewValidName = (elem, newName) => {
        if (elem.type === "connection") {
            return !newName.includes("\n") && elem.name !== newName;
        } else return newName !== "" && !newName.includes("\n") && elem.name !== newName;
    }

    const hasNewValidProperties = (elem, newProperties) => {
        //TODO: Do this properly <- find the best way to check if the properties have changed -> exclude whitespace changes
        let changes = false;
        for (let i = 0; i < elem.properties.length; i++) {
            if (newProperties[i].key !== "" && newProperties[i].key !== elem.properties[i].key) {
                changes = true;
                break;
            }

            if (newProperties[i].value !== "" && newProperties[i].value !== elem.properties[i].value) {
                changes = true;
                break;
            }
        }

        return changes;
    }

    const hasChangedSource = (link, newSource) => {
        if (link.source !== null && link.source !== undefined) {
            if (newSource !== null && newSource !== undefined) {
                return link.source.id !== newSource.id;
            } else return true;
        } else return newSource !== null && newSource !== undefined;
    }

    const hasChangedTarget = (link, newTarget) => {
        if (link.target !== null && link.target !== undefined) {
            if (newTarget !== null && newTarget !== undefined) {
                return link.target.id !== newTarget.id;
            } else return true;
        } else return newTarget !== null && newTarget !== undefined;
    }

    const handleEditElem = useCallback(
        (e) => {
            e.preventDefault();

            let somethingChanged = false;

            let newProperties = [];

            let newValue = "";
            let additionalValueText = "";

            if (selectedElement.type === "component") {
                for (let i = 0; i < selectedElement.properties.length; i++) {
                    let key = document.getElementById("prop-" + i + "-key").value;
                    let value = document.getElementById("prop-" + i + "-value").value;
                    newProperties.push({ key: key, value: value });
                }

                if (hasNewValidProperties(selectedElement, newProperties)) {
                    additionalValueText = "\n\n";
                    newProperties.forEach(prop => {
                        if (prop.key !== "" && prop.value !== "") {
                            additionalValueText += prop.key + ":" + prop.value + "\n";
                        }
                    });

                    newValue = capitalize(selectedElement.name) + additionalValueText;
                    somethingChanged = true;
                }
            }

            if (hasNewValidName(selectedElement, newElemName)) {

                selectedElement.name = newElemName;
                newValue = capitalize(newElemName);

                if (selectedElement.type === "cloud") {
                    newValue += "\n<<network>>";
                }
                else if (selectedElement.type === "component") {
                    let prevValueSplit = selectedElement.cell.value.split("\n").splice(1);

                    if (additionalValueText !== "") {
                        newValue += additionalValueText;
                    } else {
                        newValue += "\n\n";
                        prevValueSplit.forEach(slice => {
                            if (slice !== "") {
                                newValue += slice + "\n";
                            }
                        });
                    }
                }

                //TODO: Add descriptive change log
                setChanges([...changes, `MOD ${selectedElement.name} - ${selectedElement.id} at ${new Date().toString()}`]);

                somethingChanged = true;
            }

            if (somethingChanged) {
                selectedElement.value = newValue;
                graph.maxGraph.model.setValue(selectedElement.cell, newValue);
                setSelectedElement(null);
                setSelectedElemChildren([]);
            } else {
                //TODO: ADD Better Error messages + more cases
                addAlertMessage("Nothing changed, please check whatcha doing!", "danger", "edit-alert-msg");
                cleanAllAlertMessages(null, "edit-alert-msg");
            }

        }, [graph, changes, setChanges, selectedElement, newElemName]
    );

    const handleEditLink = useCallback(
        (e) => {
            e.preventDefault();

            //TODO: Allow to change link connection type

            let newLinkText = document.getElementById("link-text").value;

            let hasChanges = false;

            if (hasNewValidName(selectedLink, newLinkText)) {
                hasChanges = true;
                selectedLink.name = newLinkText;
                selectedLink.value = newLinkText;
                graph.maxGraph.model.setValue(selectedLink.cell, newLinkText);
            }

            if (hasChangedSource(selectedLink, selectedLinkSrc)) {
                hasChanges = true;
                if (selectedLinkSrc === null || selectedLinkSrc === undefined) {
                    selectedLink.source = null;
                    graph.maxGraph.model.setTerminal(selectedLink.cell, undefined, true);
                } else {
                    selectedLink.source = selectedLinkSrc;
                    graph.maxGraph.model.setTerminal(selectedLink.cell, selectedLinkSrc.cell, true);
                }
            }

            if (hasChangedTarget(selectedLink, selectedLinkTgt)) {
                hasChanges = true;
                if (selectedLinkTgt === null || selectedLinkTgt === undefined) {
                    selectedLink.target = null;
                    graph.maxGraph.model.setTerminal(selectedLink.cell, undefined, false);
                } else {
                    selectedLink.target = selectedLinkTgt;
                    graph.maxGraph.model.setTerminal(selectedLink.cell, selectedLinkTgt.cell, false);
                }
            }

            if (hasChanges) {
                setSelectedLink(null);
                setSelectedLinkSrc(null);
                setSelectedLinkTgt(null);

                //TODO: Add descriptive change log
                setChanges([...changes, `MOD ${selectedLink.name} - ${selectedLink.id} at ${new Date().toString()}`]);
            }
            else {
                //TODO: ADD Better Error messages + more cases
                addAlertMessage("Nothing changed, please check whatcha doing!", "danger", "edit-alert-msg");
                cleanAllAlertMessages(null, "edit-alert-msg");
            }

        }, [graph, changes, setChanges, selectedLink, selectedLinkSrc, selectedLinkTgt]
    );

    const changeLinkTerminal = useCallback(
        (source = true) => {
            let htmlElemId = "link-" + (source ? "src" : "tgt") + "-dropdown";
            let dropdownSelected = document.getElementById(htmlElemId);

            if (dropdownSelected !== null || dropdownSelected !== undefined) {
                console.log("Selected Terminal:", dropdownSelected);
                let newTerminal = graph.findElement(dropdownSelected.value);

                source ? setSelectedLinkSrc(newTerminal) : setSelectedLinkTgt(newTerminal);
            }

        }, [graph]
    );

    const enableLinkTerminalDropdown = useCallback(
        (e) => {
            e.preventDefault();
            let targetId = e.target.id;
            let htmlElemId = "";
            let source = false;
            let add = false;

            if (targetId.includes("link-src")) {
                source = true;
            } else if (!targetId.includes("link-tgt")) {
                console.error("Something went wrong, please try again");
                return;
            }

            if (targetId.includes("-add")) {
                add = true;
            } else if (!targetId.includes("-edit")) {
                console.error("Something went wrong, please try again");
                return;
            }

            if (source) {
                htmlElemId = "link-src-dropdown";
                if (add) setSelectedLinkSrc(graph.elements[0]);
            } else {
                htmlElemId = "link-tgt-dropdown";
                if (add) setSelectedLinkTgt(graph.elements[0]);
            }

            setTimeout(() => {
                let dropdownSelected = document.getElementById(htmlElemId);
                if (dropdownSelected !== null || dropdownSelected !== undefined) {
                    dropdownSelected.disabled = false;
                } else {
                    if (add) {
                        source ? setSelectedLinkSrc(null) : setSelectedLinkTgt(null);
                    }
                }
            }, 500);
        }, [graph]
    );

    return (
        <div className="col-3 shadow-sm" id="right-menu">
            {selectedElement !== null ?
                <>
                    <div className="bg-light shadow-sm p-2">
                        <h5 className='mb-3'>Element Details</h5>
                        <div className='input-group mb-3'>
                            <span className='input-group-text'>ID:</span>
                            <input type='text' className='form-control' id="elem-id" style={{ fontSize: ".8rem" }} value={selectedElement.id} disabled />
                        </div>
                        <div className='input-group mb-3'>
                            <span className='input-group-text'>Type:</span>
                            <input type='text' className='form-control' id="elem-type" style={{ fontSize: ".8rem" }} value={selectedElement.type} disabled />
                        </div>
                        <div className='input-group mb-3'>
                            <span className='input-group-text'>Name:</span>
                            <input type='text' className='form-control' id="element-name" style={{ fontSize: ".8rem" }} value={newElemName} onChange={(e) => { setNewElemName(e.target.value) }} />
                        </div>
                        {
                            selectedElement.type === "component" ?
                                selectedElement.properties.length !== 0 ?
                                    <>
                                        <div className='input-group-text d-flex justify-content-between'>
                                            Properties:
                                            <div>
                                                <i className="bi bi-plus-circle-fill" id="comp-prop-add" style={{ color: "#83c5be" }} onClick={() => { console.log("TODO: add property") }} />
                                            </div>
                                        </div>
                                        {selectedElement.properties.map((item, index) => (
                                            <div className='input-group mt-3' key={index}>
                                                <input type='text' className='form-control' id={"prop-" + index + "-key"} style={{ fontSize: ".8rem" }} defaultValue={item.key} />
                                                <span className='input-group-text'>:</span>
                                                <input type='text' className='form-control' id={"prop-" + index + "-value"} style={{ fontSize: ".8rem" }} defaultValue={item.value} />
                                                <i className="bi bi-x-circle-fill p-2" style={{ color: "#a41623" }} onClick={() => { console.log("TODO: delete property") }} />
                                            </div>
                                        ))}
                                        <div className="mb-3" />
                                    </> :
                                    <div className='input-group-text d-flex justify-content-between mb-3'>
                                        Properties:
                                        <i className="bi bi-plus-circle-fill" id="comp-prop-add" style={{ color: "#83c5be" }} onClick={() => { console.log("TODO: add property") }} />
                                    </div>
                                :
                                <></>
                        }
                        {
                            selectedElemChildren.length !== 0 ?
                                <>
                                    <div className='input-group-text d-flex justify-content-between mb-3'>
                                        Children:
                                        <div>
                                            <i className="bi bi-plus-circle-fill" id="comp-prop-add" style={{ color: "#83c5be" }} onClick={() => { console.log("TODO: add child of elem") }} />
                                        </div>
                                    </div>
                                    {selectedElemChildren.map((child, index) => (
                                        <p className="bg-light shadow-sm text-center p-1 elem-list-item" key={index} onClick={handleClickElem}>
                                            {child.value !== undefined ? child.value.split("\n")[0] + " " + child.id : child.id}
                                            {/* TODO: Should I have a unlink/delete child? */}
                                        </p>
                                    ))}
                                </> :
                                selectedElement.type === "node" || selectedElement.type === "package" ?
                                    <div className='input-group-text d-flex justify-content-between mb-3'>
                                        Children:
                                        <i className="bi bi-plus-circle-fill" id="comp-prop-add" style={{ color: "#83c5be" }} onClick={() => { console.log("TODO: add child of elem") }} />
                                    </div> : <></>
                        }
                        <div className='d-flex justify-content-between mt-xl-4 mt-sm-1 mb-xl-4 mb-sm-1'> {/* TODO: Improve CSS in here */}
                            <button className="btn btn-primary btn-resized" onClick={handleEditElem}> Save Changes</button>
                            <button className="btn btn-danger btn-resized" onClick={() => { setSelectedElement(null); setNewElemName(""); setSelectedElemChildren([]); }}>Go back</button>
                        </div>
                        <div id="edit-alert-msg" className="mb-1 alert" role="alert" />
                    </div>
                </> :
                <></>
            }
            {selectedLink !== null ?
                <>
                    <div className="bg-light shadow-sm p-2">
                        <h5 className='mb-3'>Link Details</h5>
                        <div className='input-group mb-3'>
                            <span className='input-group-text'>ID:</span>
                            <input type='text' className='form-control' id="link-id" style={{ fontSize: ".8rem" }} defaultValue={selectedLink.id} disabled />
                        </div>
                        <div className='input-group mb-3'>
                            <span className='input-group-text'>Text:</span>
                            <input type='text' className='form-control' id="link-text" style={{ fontSize: ".8rem" }} defaultValue={selectedLink.name} />
                        </div>
                        {
                            selectedLinkSrc !== null && selectedLinkSrc !== undefined ?
                                <>
                                    <div className='input-group-text d-flex justify-content-between'>
                                        Source:
                                        <div>
                                            <i className="bi bi-pencil-fill me-2" id="link-src-edit" style={{ color: "#e19b20" }} onClick={enableLinkTerminalDropdown} />
                                            <i className="bi bi-x-circle-fill" style={{ color: "#a41623" }} onClick={() => setSelectedLinkSrc(null)} />
                                        </div>
                                    </div>
                                    <div className="bg-light shadow-sm text-center p-1">
                                        <select className='form-select form-control' id='link-src-dropdown' defaultValue={selectedLinkSrc.id} onChange={() => { changeLinkTerminal() }} disabled>
                                            {graph.elements.map((elem) => (
                                                <option className="bg-light shadow-sm p-1" key={elem.id} value={elem.id} style={selectedLink.source !== null && elem.id === selectedLink.source.id ? { color: "#83c5be", fontWeight: "bold" } : {}}>
                                                    {(elem.name !== undefined ? capitalize(elem.name) + " <" : " <") + capitalize(elem.type) + "> " + elem.id}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </> :
                                <div className='input-group-text d-flex justify-content-between mb-3'>
                                    Source:
                                    <i className="bi bi-plus-circle-fill" id="link-src-add" style={{ color: "#83c5be" }} onClick={enableLinkTerminalDropdown} />
                                </div>
                        }
                        {
                            selectedLinkTgt !== null && selectedLinkTgt !== undefined ?
                                <>
                                    <div className='input-group-text d-flex justify-content-between'>
                                        Target:
                                        <div>
                                            <i className="bi bi-pencil-fill me-2" id="link-tgt-edit" style={{ color: "#e19b20" }} onClick={enableLinkTerminalDropdown} />
                                            <i className="bi bi-x-circle-fill" style={{ color: "#a41623" }} onClick={() => setSelectedLinkTgt(null)} />
                                        </div>
                                    </div>
                                    <div className="bg-light shadow-sm text-center p-1">
                                        <select className='form-select form-control' id='link-tgt-dropdown' defaultValue={selectedLinkTgt.id} onChange={() => { changeLinkTerminal(false) }} disabled>
                                            {graph.elements.map((elem) => (
                                                <option className="bg-light shadow-sm p-1" key={elem.id} value={elem.id} style={selectedLink.target !== null && elem.id === selectedLink.target.id ? { color: "#83c5be", fontWeight: "bold" } : {}}>
                                                    {(elem.name !== undefined ? capitalize(elem.name) + " <" : " <") + capitalize(elem.type) + "> " + elem.id}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </> :
                                <div className='input-group-text d-flex justify-content-between mb-3'>
                                    Target:
                                    <i className="bi bi-plus-circle-fill" id="link-tgt-add" style={{ color: "#83c5be" }} onClick={enableLinkTerminalDropdown} />
                                </div>
                        }
                        <div className='d-flex justify-content-between mt-xl-4 mt-sm-1 mb-xl-4 mb-sm-1'> {/* TODO: Improve CSS in here */}
                            <button className="btn btn-primary btn-resized" onClick={handleEditLink}> Save Changes</button>
                            <button className="btn btn-danger btn-resized" onClick={() => { setSelectedLink(null); setSelectedLinkSrc(null); setSelectedLinkTgt(null); }}>Go back</button>
                        </div>
                        <div id="edit-alert-msg" className="mb-1 alert" role="alert" />
                    </div>
                </> :
                <></>
            }
            {selectedElement === null && selectedLink === null ?
                <>
                    <h5>Graph Details</h5>
                    <h6>Elements</h6>
                    {
                        graph !== null ?
                            graph.elements.map((item, index) => (
                                <p className="bg-light shadow-sm text-center p-1 elem-list-item" key={index} onClick={handleClickElem}>
                                    {item.value !== undefined ? item.value.split("\n")[0] + " " + item.id : item.id}
                                </p>
                            )) :
                            <></>
                    }
                    <h6>Links</h6>
                    {
                        graph !== null ?
                            graph.links.map((item, index) => (
                                <p className="bg-light shadow-sm text-center p-1 link-list-item" key={index} onClick={handleClickLink}>
                                    {item.value !== undefined ? item.value.split("\n")[0] + " " + item.id : item.id}
                                </p>
                            )) :
                            <></>
                    }
                </> : <></>
            }
        </div>
    );
}
export default EditMenuVisual;