import { useCallback, useEffect, useState } from "react";
import BackendApi from "../BackendApi";
import { updatePreviewPlantUML, submitPlantUMLGraph } from "../pages/PlantUMLEditor";
import { addAlertMessage, cleanAllAlertMessages } from "../utils/utils";

function RepoDetailsForm(props) {
    const enableVisualEditor = false; //FLAG to enable Visual Editor access through frontend

    const formTitle = props.formTitle;
    const inEditorPage = props.inEditorPage;
    const leftBtnText = props.leftBtnText;
    const activeEditor = props.activeEditor;
    const validRepoNameRegex = /^[A-Za-z0-9-_.~:?#[\]()@!$&'*+,;%=]+\/[A-Za-z0-9-_.~:?#[\]()@!$&'*+,;%=]+$/;
    
    const [gotBranches, setGotBranches] = useState(false);
    const [rowStyle, setRowStyle] = useState({});
    const [repoName, setRepoName] = useState(inEditorPage ? props.repoName : '');
    const [canInputExtraDetails, setCanInputExtraDetails] = useState(inEditorPage ? true : false);
    const [branchList, setBranchList] = useState([]);
    const [branch, setBranch] = useState(inEditorPage ? props.branch : '');
    const [readme, setReadme] = useState(inEditorPage ? props.readme : false);
    const [highlight, setHighlight] = useState(inEditorPage ? props.highlight : false);

    const [loadingRequest, setLoadingRequest] = useState(false);
  
    const getBranches = useCallback((repoName, repoInput = null) => {
      //Get the branches of the repository
      let requestData = JSON.stringify({ 'repository': repoName });
      let headers = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8"
        }
      }
  
      BackendApi.post('check_branches', requestData, headers).then(res => {
        if (res.status === 200) {
          setBranchList(res.data.branches);
        }
      }).catch(err => {
        if(err.response.status === 422){
          addAlertMessage('Please check that your repository name is correct.', 'danger');
        } else {
          addAlertMessage('Please insert "{user|organization}/{repository name}"', 'danger');
        }
        cleanAllAlertMessages();
  
        if (repoInput){
          repoInput.style.borderColor = 'coral';
        }
  
        if (canInputExtraDetails) {
          setCanInputExtraDetails(false);
          setBranchList([]);
        }
      });
    }, [canInputExtraDetails]);

    useEffect(() => {
      if (inEditorPage && !gotBranches) {
        getBranches(repoName);
        setGotBranches(true);
      }

      if(inEditorPage && activeEditor === 'plantuml') {
        setRowStyle({marginLeft: "4rem", marginRight: "4rem"});
      } else {
        setRowStyle({marginLeft: "1rem", marginRight: "1rem"});
      }
    }, [getBranches, gotBranches, inEditorPage, repoName, activeEditor, branch, readme, highlight]);

  const handlePreviewEditBtnClick = (currEditor, selectedEditor, repoOptions) =>{
    if(currEditor === selectedEditor) {
      currEditor === 'plantuml' ? updatePreviewPlantUML(repoOptions.highlight) : console.log('TODO: Visual Editor Preview');
    } else {
      redirectToEditorPage(selectedEditor, repoOptions.repoName, repoOptions.branch, repoOptions.readme);
    }
  }

  const redirectToEditorPage = (editorType, repoName, branch, readme) => {
    window.location.href = `/view/${editorType}?repo=${repoName}&branch=${branch}&readme=${readme}`;
  }

  const handleInputRepoName = (e) => {
    //Used only for the Home/Analyze page
    e.preventDefault();
    const repoNameInput = e.target.value;

    if (validRepoNameRegex.test(repoNameInput)) {
      e.target.style.borderColor = null;

      //Enable form elements after checking the repository name
      setRepoName(repoNameInput);
      setCanInputExtraDetails(true);
      setBranchList([]);

      //Get the branches of the repository
      getBranches(repoNameInput, e.target);
    } else {
      e.target.style.borderColor = 'coral';

      if (canInputExtraDetails) {
        setCanInputExtraDetails(false);
        setBranchList([]);
      }

      addAlertMessage('Please insert "{user|organization}/{repository name}"', 'danger');
      cleanAllAlertMessages();
    }
  }

  const updateFormValuesState = () => {
    let selectedBranch = document.getElementById('branch-dropdown').value;
    let addToReadme = document.getElementById('readme-checkbox').checked;
    let highlightChanges = inEditorPage ? document.getElementById('highlight-checkbox').checked : false;

    setBranch(selectedBranch);
    setReadme(addToReadme);
    setHighlight(highlightChanges);

    return { selectedBranch, addToReadme, highlightChanges };
  }

  const onClickLBtn = (e) => {
    e.preventDefault();
    let { selectedBranch, addToReadme } = updateFormValuesState();

    if (repoName === '') {
      addAlertMessage("Please enter a repository name: '{user|organization}/{repository name}'", 'danger');
      cleanAllAlertMessages();
      return;
    }

    if (selectedBranch === '') {
      addAlertMessage('Please select a branch from the branch dropdown.', 'danger');
      cleanAllAlertMessages();
      return;
    }

    if (!inEditorPage) {
      //Analyze Repository

      let requestData = JSON.stringify({
        'repository': repoName,
        'branch': selectedBranch,
        'add_readme': addToReadme
      });
      let headers = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8"
        }
      }

      setLoadingRequest(true);

      BackendApi.post('analyze', requestData, headers).then(res => {
        if (res.status === 200) {
          setLoadingRequest(false);
          addAlertMessage(res.data, 'success');
          cleanAllAlertMessages()
        }
      }).catch(err => {
        setLoadingRequest(false);
        if(err.response.status === 422){
          addAlertMessage('The specified repository is not valid.\nDoes it have a valid docker-compose file?', 'danger');
        } else {
          addAlertMessage('Error Analyzing Repository ('+ err.response.status +'): '+ err.response.data, 'danger');
        }
        cleanAllAlertMessages();
      });
    }
    else {
      //Submit Changes

      if(activeEditor === 'plantuml'){
        submitPlantUMLGraph(
          setLoadingRequest,
          { 
            repoName: repoName,
            branch: selectedBranch,
            readme: addToReadme 
          });
        } else {
          console.log("TODO: Submit Visual Editor Graph");
        }
      }
  }

  const onClickRBtn = (e) => {
    e.preventDefault();
    let { selectedBranch, addToReadme, highlightChanges } = updateFormValuesState();

    if (selectedBranch === '') {
      addAlertMessage('Please select a branch from the branch dropdown.', 'danger');
      cleanAllAlertMessages();
      return;
    }

    if (!inEditorPage) {
      //Change to Preview & Edit Page with PlantUML Editor (default)
      redirectToEditorPage('plantuml', repoName, selectedBranch, addToReadme);
    }
    else {
      //Preview Changes Made with current active editor

      handlePreviewEditBtnClick(activeEditor, activeEditor, { 
        repoName: repoName,
        branch: selectedBranch,
        readme: addToReadme, 
        highlight: highlightChanges });
    }
  }

  const onClickDropDownPlantUML = (e) => {
    e.preventDefault();
    let { selectedBranch, addToReadme, highlightChanges } = updateFormValuesState();

    if (selectedBranch === '') {
      addAlertMessage('Please select a branch from the branch dropdown.', 'danger');
      cleanAllAlertMessages();
      return;
    }

    if (!inEditorPage) {
      //Change to Preview and Edit Page with PlantUML Editor
      redirectToEditorPage('plantuml', repoName, selectedBranch, addToReadme);
    }
    else {
      //Preview Changes Made PlantUML - check page + change / update'
      handlePreviewEditBtnClick(activeEditor, 'plantuml', { 
        repoName: repoName,
        branch: selectedBranch,
        readme: addToReadme,
        highlight: highlightChanges});
    }
  }

  const onClickDropDownVisualEditor = (e) => {
    e.preventDefault();
    let { selectedBranch, addToReadme, highlightChanges } = updateFormValuesState();
  
    if (selectedBranch === '') {
      addAlertMessage('Please select a branch from the branch dropdown.', 'danger');
      cleanAllAlertMessages();
      return;
    }

    if (!inEditorPage) {
      //Change to Preview and Edit Page with Visual Editor
      redirectToEditorPage('visual', repoName, selectedBranch, addToReadme);
    }
    else {
      //'Preview Changes Made Visual - check page + change / update'
      handlePreviewEditBtnClick(activeEditor, 'visual', { 
        repoName: repoName,
        branch: selectedBranch,
        readme: addToReadme,
        highlight: highlightChanges });  
    }
  }


    return (
      <div className='row text-start my-5 py-2 py-lg-5' style={rowStyle}>
        <form>
          <h4 className='form-label mb-3' id="repo-form">{formTitle}</h4>

          {inEditorPage ? <></> :
            <div className='input-group repo-form-resized mb-3'>
              <span className='input-group-text repo-form-label' id="github_link">https://github.com/</span>
              <input type='text' className='form-control' aria-describedby='github_link' id="repo-url" onBlur={handleInputRepoName} />
            </div>
          }

          <div className='input-group repo-form-resized mb-3'>
            <span className='input-group-text repo-form-label'>Choose a branch</span>
            <select className='form-select form-control' id='branch-dropdown' disabled={!canInputExtraDetails && !inEditorPage}>
              {branchList.map((branch) => (
                <option key={branch} value={branch}>{branch}</option>
              ))}
            </select>
          </div>
          <div className="form-check form-check-inline form-switch mb-3">
            <input className="form-check-input" type="checkbox" role="switch" id="readme-checkbox" disabled={!canInputExtraDetails && !inEditorPage}/>
            <label className="form-check-label" htmlFor="readme-checkbox">Add to Readme</label>
          </div>

          {inEditorPage ?
            <div className="form-check form-check-inline form-switch mb-3">
              <input className="form-check-input" type="checkbox" role="switch" id="highlight-checkbox"/>
              <label className="form-check-label" htmlFor="highlight-checkbox">Highlight Changes</label>
            </div>
            : <></>}

          <div className="mt-4 mb-4">
            <button id="analyze-btn" type="submit" className="btn btn-primary me-2" style={{ width: '150px', height: '45px' }} onClick={onClickLBtn} disabled={loadingRequest}>{leftBtnText}</button>
            <div className="btn-group">
              <button id="preview-edit-btn" type="submit" className="btn btn-secondary" style={{ width: '150px', height: '45px' }} onClick={onClickRBtn} disabled={!canInputExtraDetails && !inEditorPage}>Preview & Edit</button>
              { enableVisualEditor ?
                <> 
                  <button id="prev-edit-dropdown-btn" type="button" className="btn btn-secondary dropdown-toggle dropdown-toggle-split editor-dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" disabled={!canInputExtraDetails && !inEditorPage} />
                  <ul className="dropdown-menu">
                    <li><button id="edit-plantuml-btn" className="dropdown-item editor-dropdown-item" onClick={onClickDropDownPlantUML}>Edit with PlantUML</button></li>
                    <li><button id="edit-visual-btn" className="dropdown-item editor-dropdown-item" onClick={onClickDropDownVisualEditor}>Edit with Visual Editor</button></li>
                  </ul> 
                </> : <></>
              }
            </div>
          </div>
          {loadingRequest ?
            <div className="spinner-grow mt-2 ms-3" role="status">
              <span className="sr-only"></span>
            </div>
          : <></>}
          <div id="alert-message" className="mb-4 alert" role="alert" />
        </form>
      </div>
    );
}
export default RepoDetailsForm;