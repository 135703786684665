import ButtonLink from "../components/ButtonLink";

function NotFound() {
    return (
      <div className="container text-center mx-auto">
        <h2>Oops!</h2>
        <h4 className="text-muted mt-5"> The page you were looking for doesn't exist!</h4>
        <h6 className="text-muted">404 - Page Not Found</h6>
        <h4 className="mt-4">
            <i className="bi bi-emoji-frown-fill" style={{ fontSize: '3.5rem' }}/>
        </h4>

        <ButtonLink classes='btn btn-primary btn-resized mt-5' link='/' btnText='Start Here' />
      </div>
    );
  }
  export default NotFound;