function Footer() {
  return (
    <footer className="bg-light mt-5">
        <div className="container pb-1 pb-lg-5" id="contacts">
            <div className="row text-center mx-lg-auto">
                <div className="mt-5">
                    <p className="text-muted small">
                        <i className="bi bi-envelope-fill pe-2"></i>infrastructuregenie@gmail.com
                    </p>
                </div>
                <div className="mt-4">
                    <p className="text-muted">Faculty of Engineering of the University of Porto</p>
                    <p className="text-muted small ">
                        <i className="bi bi-cursor-fill pe-2"></i> www.fe.up.pt 
                        <i className="bi bi-envelope-fill pe-2 ps-2"></i>feup@fe.up.pt
                    </p>
                    <p className="text-muted small ">
                        <i className="bi bi-geo-alt-fill pe-2"></i>Rua Dr. Roberto Frias, s/n 4200-465 Porto, Portugal
                    </p>
                    <p className="text-muted small ">
                        <i className="bi bi-telephone-fill pe-2"></i>+351 22 508 14 00
                    </p>
                </div>
                <div className="row">
                    <hr className="my-5"></hr>
                </div>
            </div>
        </div>
    </footer>
  );
}
export default Footer;