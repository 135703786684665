let timeoutId = null;
export function delay(callback, ms) {
    if (timeoutId !== null) {
        clearTimeout(timeoutId);
        timeoutId = null;
    }
    timeoutId = setTimeout(() => {
        callback();
    }, ms);
}
export function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export function addAlertMessage(message, type, elemId = 'alert-message') {
    let alertMsg = document.getElementById(elemId);
  
    if(alertMsg === null) return;

    let msgClass = "alert-" + type;
    alertMsg.classList.add(msgClass);
    alertMsg.innerText = message;
    alertMsg.style.display = 'block';
  };
  
  export function resetMessage(elemId = 'alert-message') {
    let alertMsg = document.getElementById(elemId);
  
    if(alertMsg === null) return;
  
    alertMsg.style.display = 'none';
    alertMsg.innerText = '';
    alertMsg.classList.remove('alert-danger');
    alertMsg.classList.remove('alert-success');
    alertMsg.classList.remove('alert-info');
  }
  
  export function cleanAllAlertMessages(timeout = null, elemId = 'alert-message') {
    setTimeout(() => {
        resetMessage(elemId);
    }, timeout ? timeout : 5000);
  }
