import ButtonLink from "../components/ButtonLink";

function BadRepo() {
    return (
      <div className="container text-center mx-auto">
        <h2>Oops!</h2>
        <h4 className="text-muted mt-5"> The specified repository is not valid.</h4>
        <h6 className="text-muted">Does it have a valid docker-compose file?</h6>
        <h4 className="mt-4">
            <i className="bi bi-emoji-tear-fill" style={{ fontSize: '3.5rem' }}/>
        </h4>

        <ButtonLink classes='btn btn-primary btn-resized mt-5' link='/' btnText='Start Here' />
      </div>
    );
  }
  export default BadRepo;